//Auth Slice For Login And Logout
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CREATE_AD,
  DELETE_AD,
  EDIT_ADS,
  GET_ADS,
  GET_PRE_SIGN_URL,
  GET_SINGLE_AD,
  POINT_GRAPH,
} from "../../../constants/URLS/urls";
import { PURGE } from "redux-persist";
import { defaultMessage } from "../../../constants/Messages";
import {
  HTTP_CREATED,
  HTTP_OK,
  HTTP_UPLODED,
} from "../../../constants/StatusCode";
import axiosInstance from "../../../config/axios";
import axios from "axios";

const initialState = {
  isLoading: true,
  filter: {
    any: "",
    brand: "",
    dateRange: null,
  },
  nextPage: null,
  prevPage: null,
  adsListData: [],
  count: 0,
  totalPages: 0,
  signleAdDetails: {},
  pointGraph: [],
};

// Create Ad
export const createAds = createAsyncThunk("ads/create", async (params) => {
  try {
    const response = await axiosInstance.post(CREATE_AD, params);
    if (response.data.status_code !== HTTP_CREATED) {
      throw new Error(response.data.message[0]);
    }
    return;
  } catch (error) {
    let message = defaultMessage;
    if (error?.response?.data?.message[0]) {
      message = error.response.data.message[0];
    } else {
      message = error.message;
    }
    throw message;
  }
});

// Get Filtered Ads
export const getFilterAds = createAsyncThunk(
  "ads/getfiletedAds",
  async (_, { getState }) => {
    let url = GET_ADS;
    const state = getState();
    const { filter } = state.ad;
    const { any, brand, dateRange } = filter;
    if (any.length > 0) {
      url += `?search=${any}`;
    }
    if (brand.length > 0) {
      any.length > 0
        ? (url += `&brand_name=${brand}`)
        : (url += `?brand_name=${brand}`);
    }
    try {
      let response;
      dateRange === null
        ? (response = await axiosInstance.post(url))
        : (response = await axiosInstance.post(url, {
            start_date: dateRange.startDate,
            end_date: dateRange.endDate,
          }));
      if (response.status !== HTTP_OK) {
        throw new Error(response.data.message[0]);
      }
      const resData = response.data.data[0];
      return {
        data: resData.results,
        count: resData.count,
        next: resData.links.next,
        prev: resData.links.previous,
        totalPages: resData.links.total_pages,
      };
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);

// Get All Ads
export const getAllAds = createAsyncThunk(
  "ads/getAll",
  async (nextUrl = null) => {
    const url = nextUrl ? nextUrl : GET_ADS;
    try {
      const response = await axiosInstance.post(url);
      if (response.status !== HTTP_OK) {
        throw new Error(response.data.message[0]);
      }
      const resData = response.data.data[0];
      return {
        data: resData.results,
        count: resData.count,
        next: resData.links.next,
        prev: resData.links.previous,
        totalPages: resData.links.total_pages,
      };
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);

//update Ads
export const updateAds = createAsyncThunk("ads/update", async (params) => {
  try {
    const EDIT_ADS_URL = EDIT_ADS + `${params[1]}/`;

    const response = await axiosInstance.patch(EDIT_ADS_URL, params[0]);
    if (response.data.status_code !== HTTP_OK) {
      throw new Error(response.data.message[0]);
    }
    return { params };
  } catch (error) {
    let message = defaultMessage;
    if (error?.response?.data?.message[0]) {
      message = error.response.data.message[0];
    } else {
      message = error.message;
    }
    throw message;
  }
});

// get single ad
export const getSingleAd = createAsyncThunk("ads/getSingle", async (id) => {
  try {
    const GET_SINGLE_AD_URL = GET_SINGLE_AD + `${id}/`;

    const response = await axiosInstance.get(GET_SINGLE_AD_URL);

    if (response.status !== HTTP_OK) {
      throw new Error(response.data.message[0]);
    }
    return response.data.data;
  } catch (error) {
    let message = defaultMessage;
    if (error?.response?.data?.message[0]) {
      message = error.response.data.message[0];
    } else {
      message = error.message;
    }

    throw message;
  }
});

const upload = async (url, formData, file) => {
  try {
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === HTTP_UPLODED) {
      return file.name;
    }
  } catch (error) {
    let message = defaultMessage;
    if (error?.response?.data?.message[0]) {
      message = error.response.data.message[0];
    } else {
      message = error.message;
    }
    return message;
  }
};

// Upload File To AWS
export const uploadFileToAWS = createAsyncThunk("ads/upload", async (file) => {
  try {
    let AWS_URL = GET_PRE_SIGN_URL + file.name;
    const response = await axiosInstance.get(AWS_URL);

    if (response.status !== HTTP_OK) {
      throw new Error(response.data.message[0]);
    }

    let formData = new FormData();
    formData.append("key", response.data.data?.fields?.key);
    formData.append(
      "AWSAccessKeyId",
      response.data.data?.fields?.AWSAccessKeyId
    );
    formData.append("policy", response.data.data?.fields?.policy);
    formData.append("signature", response.data.data?.fields?.signature);
    formData.append("file", file);

    return upload(response.data.data.url, formData, file);
  } catch (error) {
    let message = defaultMessage;
    if (error?.response?.data?.message[0]) {
      message = error.response.data.message[0];
    } else {
      message = error.message;
    }
    throw message;
  }
});

//Delete Ads
export const deleteAds = createAsyncThunk("ads/delete", async (id) => {
  try {
    const DELETE_ADS_URL = DELETE_AD + `${id}/`;
    const response = await axiosInstance.patch(DELETE_ADS_URL, {
      ad_status: "DELETED",
    });
    if (response.data.status_code !== HTTP_OK) {
      throw new Error(response.data.message[0]);
    }
    return { id };
  } catch (error) {
    let message = defaultMessage;
    if (error?.response?.data?.message[0]) {
      message = error.response.data.message[0];
    } else {
      message = error.message;
    }
    throw message;
  }
});

export const getPointGraphData = createAsyncThunk(
  "ads/pointGraph",
  async (params) => {
    try {
      const response = await axiosInstance.post(POINT_GRAPH, params);
      if (response.data.status_code !== HTTP_OK) {
        throw new Error(response.data.message[0]);
      }
      return response.data?.data;
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);

const adSlice = createSlice({
  name: "ad",
  initialState,
  reducers: {
    updateFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    resetAdList: (state, action) => {
      state.adsListData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
    builder.addCase(getAllAds.pending, (state, action) => {
      state.isLoading = action.meta.arg === undefined ? true : false;
    });
    builder.addCase(getAllAds.fulfilled, (state, action) => {
      state.isLoading = false;
      state.adsListData.push(...action.payload.data);
      state.count = action.payload.count;
      state.nextPage = action.payload.next;
      state.prevPage = action.payload.prev;
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(getAllAds.rejected, (state, action) => {
      state.isLoading = false;
      state.adsListData = [];
    });
    builder.addCase(getFilterAds.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getFilterAds.fulfilled, (state, action) => {
      state.isLoading = false;
      state.adsListData = action.payload.data;
      state.count = action.payload.count;
      state.nextPage = action.payload.next;
      state.prevPage = action.payload.prev;
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(getFilterAds.rejected, (state, action) => {
      state.isLoading = false;
      state.adsListData = [];
    });
    builder.addCase(getSingleAd.fulfilled, (state, action) => {
      state.signleAdDetails = action.payload;
    });
    builder.addCase(deleteAds.fulfilled, (state, action) => {
      state.adsListData = state.adsListData.filter(
        (ad) => ad.id !== action.payload.id
      );
    });
    builder.addCase(updateAds.fulfilled, (state, action) => {
      state.adsListData = state.adsListData.map((ad) =>
        ad.id === action.payload.params[1]
          ? {
              ...ad,
              ...action.payload.params[0],
            }
          : ad
      );
    });

    builder.addCase(getPointGraphData.fulfilled, (state, action) => {
      state.pointGraph = action.payload?.app_detail ?? [];
    });
  },
});

export const { updateFilter, resetAdList } = adSlice.actions;
export default adSlice.reducer;

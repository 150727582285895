import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { DASHBOARD_ROUTE, HOMEPAGE_ROUTE } from "../../constants/routes";

const AuthWrapper = (props) => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  if (props.required && !isLoggedIn) {
    return <Navigate to={HOMEPAGE_ROUTE} />;
  }
  if (!props.required && isLoggedIn) {
    return <Navigate to={DASHBOARD_ROUTE} />;
  }
  return <>{props.children}</>;
};

export default AuthWrapper;

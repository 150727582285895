import { Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getOrderDetails } from "../../app/slices/Orders/ordersSlice";
import { setSnackBar } from "../../app/slices/Snackbar/snackbarSlice";
import OrderDetailsHeader from "../../components/OrderDetails/OrderDetailsHeader";
import OrderSummary from "../../components/OrderDetails/OrderSummary";
import ProductListing from "../../components/OrderDetails/ProductListing";
import { severity } from "../../constants/Messages";

const OrderDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!location?.state?.orderId) {
      navigate(-1);
    } else {
      dispatch(getOrderDetails({ id: location?.state?.orderId }))
        .unwrap()
        .catch((error) => {
          dispatch(
            setSnackBar({
              severity: severity.error,
              message: error,
            })
          );
          navigate(-1);
        });
    }
  }, [location, navigate, dispatch]);

  return (
    <>
      <Helmet title="Order Detail" />
      <Typography
        variant="adh2"
        sx={{
          lineHeight: "normal",
          fontWeight: 500,
        }}
      >
        Order Details
      </Typography>
      <OrderDetailsHeader />
      <OrderSummary />
      <ProductListing />
    </>
  );
};

export default OrderDetails;

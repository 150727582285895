import React from "react";
import { Snackbar, Slide, useMediaQuery, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import { resetSnackBar } from "../../app/slices/Snackbar/snackbarSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackBar = () => {
  const dispatch = useDispatch();

  const { open, severity, message } = useSelector((state) => state.snackbar);
  const theme = useTheme();
  const isMdup = useMediaQuery(theme.breakpoints.up("md"));
  const vertical = isMdup ? "bottom" : "top";
  const horizontal = "right";
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(resetSnackBar());
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <div>
      {open && (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
            TransitionComponent={TransitionLeft}
          >
            <Alert
              onClose={handleClose}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </Stack>
      )}
    </div>
  );
};

export default CustomSnackBar;

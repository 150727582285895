import axios from "axios";
import {
  HTTP_CREATED,
  HTTP_FORBIDDEN,
  HTTP_INTERNAL_SERVER_ERROR,
  HTTP_UNAUTHORIED,
  HTTP_OK,
} from "../constants/StatusCode";
import { GENERATE_TOKEN } from "../constants/URLS/urls";
import storage from "redux-persist/lib/storage";
import { updateIsRefreshing, updateToken } from "../app/slices/Auth/authSlice";
import { persistor } from "../app/store";
let store, dispatch;
export const createInstanceAndInjectStore = (_store, _dispatch) => {
  dispatch = _dispatch;
  store = _store;
};
axios.defaults.headers.post["Content-Type"] = "application/json";
const axiosInstance = axios.create();
axiosInstance.all = axios.all;
axiosInstance.spread = axios.spread;

axiosInstance.interceptors.request.use((request) => {
  const accessToken = store.getState().auth.accessToken;
  request.headers["Authorization"] = `Bearer ${accessToken}`;
  return request;
});
const redirectToLogout = () => {
  persistor.purge();
  storage.removeItem("persist:root");
  window.location.pathname = "/";
};
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const config = error.config;
    if (!store.getState().auth.isRefreshing) {
      return new Promise((resolve, reject) => {
        if (error?.response?.status === HTTP_UNAUTHORIED) {
          dispatch(updateIsRefreshing(true));
          const refreshToken = store.getState().auth.refreshToken;
          const refreshConfig = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${refreshToken}`,
            },
          };
          axios
            .post(GENERATE_TOKEN, {}, refreshConfig)
            .then((response) => {
              if (response?.data?.status_code !== HTTP_CREATED) {
                redirectToLogout();
              }
              if (
                response.status === HTTP_OK ||
                response.status === HTTP_CREATED
              ) {
                dispatch(updateToken(response.data.data.token));
                axiosInstance.defaults.headers.common["Authorization"] =
                  "Bearer " + response?.data?.data?.token?.access;
                resolve(axiosInstance(config));
              }
            })
            .catch((error) => {
              if (error?.response?.status === HTTP_INTERNAL_SERVER_ERROR) {
                //logout user
                redirectToLogout();
              }
            })
            .finally(() => {
              dispatch(updateIsRefreshing(false));
            });
        } else if (error?.response?.status === HTTP_FORBIDDEN) {
          //logout user
          redirectToLogout();
        } else if (error.response?.data?.message) {
          return reject(error);
        } else {
          return reject(error);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        const intervalId = setInterval(() => {
          if (!store.getState().auth.isRefreshing) {
            clearInterval(intervalId);
            resolve(axiosInstance(config));
          }
        }, 500);
      });
    }
  }
);

export default axiosInstance;

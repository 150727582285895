import { lazy } from "react";
import TermAndCondition from "../pages/Term&Policy/termAndCondition";
import Policy from "../pages/Term&Policy/policy";
import {
  ADTRACKING,
  CHANGEPASSWORD,
  CREATEAD,
  DASHBOARD_ROUTE,
  EDITAD,
  HELPDESK,
  PRIVACYPOLICY,
  TERMANDCONDITION,
  TIMELIMIT,
  VIEWAD,
  HEALTHSOCIAL,
  REDIRECTION,
  TERMANDCONDITIONLIGHT,
  PRIVACYPOLICYLIGHT,
  HEALTHYSOCIALMEDIALIGHT,
  REDIRECTIONLIGHT,
  ABOUTALLDAY,
  ABOUTALLDAYLIGHT,
  REFUND,
  ORDERTRACKING,
  ORDERDETAILS,
} from "../constants/routes";
import HealthySocial from "../pages/HealthySocialMedia";
import RedirectionPage from "../pages/RedirectionPage";
import RedirectionPageLight from "../pages/RedirectionPage/redirectionLight";
import HealthySocialLight from "../pages/HealthySocialMediaLight";
import PolicyLight from "../pages/Term&Policy/policyLight";
import TermAndConditionLight from "../pages/Term&Policy/termAndConditionLight";
import AboutAllDay from "../pages/AboutAllDay/aboutAllDay";
import AboutAllDayLight from "../pages/AboutAllDay/aboutAllDayLight";
import OrderDetails from "../pages/OrderDetails";
const DashboardPage = lazy(() => import("../pages/DashboardPage"));
const TimeLimitPage = lazy(() => import("../pages/TimeLimit"));
const HelpDeskPage = lazy(() => import("../pages/HelpDesk"));
const ChangePassword = lazy(() => import("../pages/ChangePassword"));
const AdList = lazy(() => import("../pages/AdTracking/AdListPage"));
const CreateAd = lazy(() => import("../pages/AdTracking/CreateAd"));
const ViewAd = lazy(() => import("../pages/AdTracking/ViewAd"));
const RefundPage = lazy(() => import("../pages/Refund"));
const OrderTrackingPage = lazy(() => import("../pages/OrderTracking"));
export const routing = [
  {
    href: DASHBOARD_ROUTE,
    component: <DashboardPage />,
    title: "Dashboard",
  },
  {
    href: ADTRACKING,
    component: <AdList />,
    title: "Ads Tracking",
  },
  {
    href: REFUND,
    component: <RefundPage />,
    title: "Refund Order",
  },
  {
    href: ORDERTRACKING,
    component: <OrderTrackingPage />,
    title: "Order Tracking",
  },
  {
    href: CREATEAD,
    component: <CreateAd />,
    title: "Create Ad",
    hasBack: true,
  },
  {
    href: EDITAD,
    component: <CreateAd />,
    title: "Edit Ad",
    hasBack: true,
  },
  {
    href: TIMELIMIT,
    component: <TimeLimitPage />,
    title: "Time Limit",
  },
  {
    href: HELPDESK,
    component: <HelpDeskPage />,
    title: "Help Desk",
  },
  {
    href: CHANGEPASSWORD,
    component: <ChangePassword />,
    title: "Change Password",
  },
  {
    href: VIEWAD,
    component: <ViewAd />,
    title: "",
    hasBack: true,
  },
  {
    href: ORDERDETAILS,
    component: <OrderDetails />,
    title: "",
    hasBack: true,
  },
];

export const otherRoute = [
  {
    href: TERMANDCONDITION,
    component: <TermAndCondition />,
    title: "Term & Condition",
  },
  {
    href: PRIVACYPOLICY,
    component: <Policy />,
    title: "Privacy Policy",
  },
  {
    href: HEALTHSOCIAL,
    component: <HealthySocial />,
    title: "Healthy Social Light",
  },
  {
    href: TERMANDCONDITIONLIGHT,
    component: <TermAndConditionLight />,
    title: "Term & Condition Light",
  },
  {
    href: PRIVACYPOLICYLIGHT,
    component: <PolicyLight />,
    title: "Privacy Policy Light",
  },
  {
    href: HEALTHYSOCIALMEDIALIGHT,
    component: <HealthySocialLight />,
    title: "Healthy Social Light",
  },
  {
    href: REDIRECTION,
    component: <RedirectionPage />,
    title: "Redirection Page",
  },
  {
    href: REDIRECTIONLIGHT,
    component: <RedirectionPageLight />,
    title: "Redirection Page Light",
  },
  {
    href: ABOUTALLDAY,
    component: <AboutAllDay />,
    title: "About AllDay Page",
  },
  {
    href: ABOUTALLDAYLIGHT,
    component: <AboutAllDayLight />,
    title: "About AllDay Page Light",
  },
];

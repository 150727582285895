import { Box } from "@mui/material";
import { useState } from "react";
import Navbar from "../../components/AppBar";
import { styled as muiStyled } from "@mui/material/styles";
import Sidebar from "../../components/Sidebar";
const Navigation = ({ hasBack, title }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 280;
  const Drawer = muiStyled("div")(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      width: `${drawerWidth}px`,
      flexShrink: 0,
    },
  }));
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Navbar
        onDrawerToggle={handleDrawerToggle}
        title={title}
        hasBack={hasBack}
      />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onOpen={handleDrawerToggle}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar open={false} onClose={() => {}} onOpen={() => {}} />
        </Box>
      </Drawer>
    </>
  );
};

export default Navigation;

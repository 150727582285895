import { Box, ListItem, Typography } from "@mui/material";
import React, { useState } from "react";
import SidebarNavItem from "./SidebarNavItem";
import LogoutIcon from "../../assets/Illustrations/Logout.svg";
import { changePassword, logout } from "./dashboardItems";
import PopupModal from "../PopupModal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSnackBar } from "../../app/slices/Snackbar/snackbarSlice";
import { persistor } from "../../app/store";
import storage from "redux-persist/lib/storage";
import { severity } from "../../constants/Messages";
import { fontFamily } from "../../constants/FontFamily";
import axiosInstance from "../../config/axios";
import { LOGOUT_API } from "../../constants/URLS/urls";
export default function SidebarFooter() {
  const [isOn, setIsOn] = useState(false);
  const [onLogout, setOnLogout] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //MAKES THE CURSOR WAIT AND POINTER BASED ON LOADING
  const cursorWait = () => (document.body.style.cursor = "wait");
  const cursorDefault = () => (document.body.style.cursor = "default");

  const handleLogout = async () => {
    setOnLogout(false);
    try {
      cursorWait();
      await axiosInstance.post(LOGOUT_API);
      await persistor.purge();
      cursorDefault();
      storage.removeItem("persist:root"); //Removing all persisted state from localstorage
      dispatch(
        setSnackBar({
          severity: severity.success,
          message: "Logged out successfully",
        })
      );
      navigate("/");
    } catch (e) {
      cursorDefault();
      await persistor.purge();
      storage.removeItem("persist:root"); //Removing all persisted state from localstorage
      dispatch(
        setSnackBar({ severity: severity.error, message: "Forced Logged Out" })
      );
      navigate("/");
    }
  };
  return (
    <>
      <Box
        component="div"
        sx={{
          paddingBottom: { lg: "15px", sm: "0px" },
          marginTop: { xl: "0px", lg: "10px" },
        }}
      >
        <SidebarNavItem item={changePassword} />
        <ListItem
          sx={{
            width: "100%",
            padding: "23px 36px",
            display: "flex",
            justifyContent: "flex-start",
            cursor: "pointer",
          }}
          onMouseOver={() => {
            setIsOn(true);
          }}
          onMouseOut={() => {
            setIsOn(false);
          }}
          onClick={() => {
            setOnLogout(true);
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              component="img"
              src={!isOn ? logout.icon : logout.activeIcon}
              alt={logout.title}
              pr={2.5}
            />
            <Typography
              variant="adh5"
              color={isOn ? "#FFFFFF" : "text.grey"}
              sx={{ textDecoration: "none", ...fontFamily.medium }}
            >
              {logout.title}
            </Typography>
          </Box>
        </ListItem>
      </Box>
      <PopupModal
        open={onLogout}
        handleClose={() => setOnLogout(false)}
        title="Log out?"
        description="Are you sure you want to log out?"
        primaryBtnText="Yes, Log out"
        primaryBtnPress={handleLogout}
        icon={LogoutIcon}
      />
    </>
  );
}

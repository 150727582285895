import moment from "moment";

export const convertToTimeString = (obj) => {
  let time = "";
  time += obj.hours < 10 ? `0${obj.hours}:` : `${obj.hours}:`;
  time += obj.mins < 10 ? `0${obj.mins}:` : `${obj.mins}:`;
  time += obj.secs < 10 ? `0${obj.secs}` : `${obj.sec}`;
  return time;
};

export const convertToLocalTime = (dateString) => {
  const localDate = moment(dateString).toDate();
  const date = moment(localDate).format("DD");
  const month = moment(localDate).format("MMM");
  const year = moment(localDate).format("YYYY");

  return `${date}th ${month}, ${year}`;
};

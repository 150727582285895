import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import BackButton from "../../assets/Icons/AppBarIcons/BackButton.svg";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fontFamily } from "../../constants/FontFamily";

function Navbar({ onDrawerToggle, title, hasBack }) {
  const { email } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const AppBar = styled(MuiAppBar)`
    background: #1e0338;
  `;
  return (
    <AppBar
      position="fixed"
      elevation={0}
      color="default"
      sx={{
        width: { md: `calc(100% - 280px)` },
        paddingTop: 2,
        borderBottom: 3,
        borderBottomColor: "primary.border",
      }}
    >
      <Toolbar>
        <Box sx={{ display: "flex", width: "100%", paddingX: 1 }}>
          <Box flexGrow={1}>
            <Box display={{ xs: "flex", md: "none" }} alignItems="center">
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon fontSize="large" />
              </IconButton>
              <Typography
                fontWeight={700}
                sx={{ lineHeight: "38.4px", ...fontFamily.bold }}
                color="#ffffff"
                variant="adh1"
              >
                {title}
              </Typography>
            </Box>
            <Box display={{ xs: "none", md: "block" }}>
              {hasBack && (
                <Box
                  component="img"
                  onClick={() => {
                    navigate(-1);
                  }}
                  height="100%"
                  sx={{ cursor: "pointer" }}
                  src={BackButton}
                  alt="back"
                  paddingRight={5}
                />
              )}
              <Typography
                fontWeight={700}
                sx={{ lineHeight: "38.4px", ...fontFamily.bold }}
                variant="adh1"
                color="white"
              >
                {title}
              </Typography>
            </Box>
          </Box>
          <Box
            flexGrow={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography
              textAlign="right"
              sx={{ lineHeight: "26px" }}
              fontWeight={500}
              color="white"
              variant="adh4"
            >
              {email}
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;

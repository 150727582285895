import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EDIT_ADS, GET_REFUND_LIST } from "../../../constants/URLS/urls";
import { PURGE } from "redux-persist";
import { defaultMessage } from "../../../constants/Messages";
import { HTTP_OK } from "../../../constants/StatusCode";
import axiosInstance from "../../../config/axios";
const initialState = {
  isLoading: false,
  filter: {
    any: "",
    status: "",
  },
  nextPage: null,
  prevPage: null,
  refundListData: [],
  count: 0,
  totalPages: 0,
};

// Get Filtered Refunds Data
export const getFilterRefund = createAsyncThunk(
  "refundData/getFilterRefund",
  async (_, { getState }) => {
    let url = GET_REFUND_LIST;
    const state = getState();
    const { filter } = state.orderRefund;
    const { any, status } = filter;
    if (any.length > 0) {
      url += `?search=${any}`;
    }
    if (status.length > 0) {
      any.length > 0
        ? (url += `&status=${status}`)
        : (url += `?status=${status}`);
    }
    try {
      const response = await axiosInstance.get(url);

      if (response.status !== HTTP_OK) {
        throw new Error(response.data.message[0]);
      }
      const resData = response.data.data[0];
      return {
        data: resData.results,
        count: resData.count,
        next: resData.links.next,
        prev: resData.links.previous,
        totalPages: resData.links.total_pages,
      };
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);
// Get All refund data
export const getAllRefundData = createAsyncThunk(
  "refund/getAllRefundData",
  async (nextUrl = null) => {
    let url = nextUrl ? nextUrl : GET_REFUND_LIST;
    try {
      const response = await axiosInstance.get(url);
      if (response.status !== HTTP_OK) {
        throw new Error(response.data.message[0]);
      }
      const resData = response.data.data[0];
      return {
        data: resData.results,
        count: resData.count,
        next: resData.links.next,
        prev: resData.links.previous,
        totalPages: resData.links.total_pages,
      };
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);

//update Ads
export const updateAds = createAsyncThunk("ads/update", async (params) => {
  try {
    const EDIT_ADS_URL = EDIT_ADS + `${params[1]}/`;

    const response = await axiosInstance.patch(EDIT_ADS_URL, params[0]);
    if (response.data.status_code !== HTTP_OK) {
      throw new Error(response.data.message[0]);
    }
    return { params };
  } catch (error) {
    let message = defaultMessage;
    if (error?.response?.data?.message[0]) {
      message = error.response.data.message[0];
    } else {
      message = error.message;
    }
    throw message;
  }
});
const orderRefundSlice = createSlice({
  name: "orderRefundSlice",
  initialState,
  reducers: {
    updateFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    resetRefundList: (state, action) => {
      state.refundListData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
    builder.addCase(getAllRefundData.pending, (state, action) => {
      state.isLoading = action.meta.arg === undefined ? true : false;
    });
    builder.addCase(getAllRefundData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.refundListData.push(...action.payload.data);
      state.count = action.payload.count;
      state.nextPage = action.payload.next;
      state.prevPage = action.payload.prev;
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(getAllRefundData.rejected, (state, action) => {
      state.isLoading = false;
      state.adsListData = [];
    });
    builder.addCase(getFilterRefund.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getFilterRefund.fulfilled, (state, action) => {
      state.isLoading = false;
      state.refundListData = action.payload.data;
      state.count = action.payload.count;
      state.nextPage = action.payload.next;
      state.prevPage = action.payload.prev;
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(getFilterRefund.rejected, (state, action) => {
      state.isLoading = false;
      state.refundListData = [];
    });
  },
});

export const { resetRefundList, updateFilter } = orderRefundSlice.actions;
export default orderRefundSlice.reducer;

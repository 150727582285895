import { createTheme } from "@mui/material";
import { themePalette } from "./colors";

export const themeInputs = createTheme(themePalette, {
  components: {
    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            "& .MuiOutlinedInput-root": {
              fontFamily: "AzoSans",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "17px",
              "& fieldset": {
                border: `1px solid${themePalette.palette.text.grey}`,
              },
              "&.Mui-focused fieldset": {
                borderColor: themePalette.palette.text.grey,
              },
            },
            "& .MuiFormHelperText-root": {
              margin: 0,
              fontFamily: "AzoSans",
            },
            "& .MuiOutlinedInput-root.Mui-error": {
              "&.Mui-focused fieldset": {
                borderColor: "#d32f2f",
              },
            },
            "&:hover": {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid",
                  borderRadius: "5px !important",
                  borderImageSlice: 1,
                  borderImageSource:
                    "linear-gradient(178.18deg, #FD749B -13.56%,#281AC8 158.3%)",
                },
              },
              "& .MuiOutlinedInput-root.Mui-error": {
                "& fieldset": {
                  border: "1px solid #d32f2f",
                },
              },
            },
          },
        },
      ],
    },
  },
});

import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NoInternetConnection from "../../assets/Illustrations/NoInternetConnection.png";
const NetWrapper = (props) => {
  const [isOnline, setOnline] = useState(true);
  const [serverError, setServerError] = useState(false);
  const handleServerError = () => {
    if (serverError) {
      window.location.reload();
    } else {
      setServerError(!serverError);
    }
  };
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);
  window.addEventListener("online", () => {
    setTimeout(() => {
      setOnline(true);
    }, 1500);
  });
  window.addEventListener("offline", () => {
    setOnline(false);
  });
  if (isOnline && !serverError) {
    return props.children;
  } else {
    return (
      <Stack
        height="100vh"
        width="100%"
        justifyContent="center"
        spacing={2}
        alignItems="center"
      >
        <Box
          component="img"
          height={400}
          width={400}
          alt="Connection Lost"
          src={NoInternetConnection}
        />
        <Typography variant="adh2" color="white">
          {serverError
            ? "Something went wrong please try again later"
            : "Please check your internet connection!"}
        </Typography>
        {serverError && (
          <Button variant="contained" onClick={handleServerError}>
            Refresh
          </Button>
        )}
      </Stack>
    );
  }
};
export default NetWrapper;

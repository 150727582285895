//Auth Slice For Login And Logout
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../config/axios";
import {
  ADD_TIME_LIMIT,
  GET_TIME_LIMIT,
  UPDATE_TIME_LIMIT,
} from "../../../constants/URLS/urls";
import { PURGE } from "redux-persist";
import { defaultMessage } from "../../../constants/Messages";
import { HTTP_OK } from "../../../constants/StatusCode";

const initialState = {
  id: null,
  time: null,
};
//Calling GetTimeLimit
export const getTimeLimit = createAsyncThunk("timeLimit/get", async () => {
  try {
    const response = await axiosInstance.get(GET_TIME_LIMIT);
    if (response.data.status_code !== HTTP_OK) {
      throw new Error(response.data.message[0]);
    }
    if (response.data.data.length === 0) {
      return { id: null, time: null };
    }
    return {
      id: response.data.data[0].id,
      time: response.data.data[0].time_limit,
    };
  } catch (error) {
    let message = defaultMessage;
    if (error?.response?.data?.message[0]) {
      message = error.response.data.message[0];
    } else {
      message = error.message;
    }
    throw message;
  }
});
export const addTimeLimit = createAsyncThunk(
  "timeLimit/add",
  async (timeData) => {
    try {
      const response = await axiosInstance.post(ADD_TIME_LIMIT, timeData);
      if (response.data.status_code !== HTTP_OK) {
        throw new Error(response.data.message[0]);
      }
      return { id: response.data.data.id, time: response.data.data.time_limit };
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);
export const updateTimeLimit = createAsyncThunk(
  "timeLimit/update",
  async (timeData) => {
    try {
      const data = {
        time_limit: timeData.time,
      };
      const response = await axiosInstance.patch(
        `${UPDATE_TIME_LIMIT}${timeData.id}/`,
        data
      );
      if (response.data.status_code !== HTTP_OK) {
        throw new Error(response.data.message[0]);
      }
      return { id: response.data.data.id, time: response.data.data.time_limit };
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);

const timeLimitSlice = createSlice({
  name: "timeLimit",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
    builder.addCase(getTimeLimit.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.time = action.payload.time;
    });
    builder.addCase(addTimeLimit.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.time = action.payload.time;
    });
    builder.addCase(updateTimeLimit.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.time = action.payload.time;
    });
  },
});

export default timeLimitSlice.reducer;

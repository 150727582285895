import { createTheme } from "@mui/material";

export const themePalette = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#BAF90B",
      appBackground: "#1E0338",
      primaryGradient:
        "linear-gradient(91.13deg, #57EBDE -49.77%, #C8FA3C 113.12%)",
      border: "#615771",
      linearMain: "linear-gradient(178.18deg, #FD749B -13.56%, #281AC8 158.3%)",
      greenGradiant:
        "linear-gradient(91.13deg, #57EBDE -49.77%, #C8FA3C 113.12%)",
      bank: "linear-gradient(86.82deg, #FD749B -11.94%, #281AC8 104.33%)",
      buttonGradiant:
        "linear-gradient(91.23deg, #6100FF -25.15%, #D300BE 111.07%)",
      outlinedGradiant: "liner-gradient(to right,#6100FF 0%,#D300BE 100% )",
      shadow: "0px 0px 10px rgba(255, 255, 255, 0.25)",
      shadow2: "0px -5px 5px rgba(255, 255, 255, 0.08)",
      goldShadow: "0px 0px 10px rgba(248, 223, 0, 0.3)",
      leftNavShadow: "4px 0px 4px rgba(255, 255, 255, 0.2)",
      linearBorderColor: "linear-gradient(to right, #6100FF, #D300BE)",
    },
    text: {
      primary: "#E6E6E6",
      grey: "#B0B0B0",
      white: "#FFFFFF",
      black: "#000000",
      secondaryBlack: "#101010",
      darkGrey: "#5B5B5B",
      secondaryDarkGrey: "#6E6E6E",
    },
    secondary: {
      main: "#A5D300",
      blue: "#00B1F7",
      pink: "#F20074",
    },
    success: {
      main: "#22B07D",
    },
    error: {
      main: "#F44336",
    },
    warning: {
      main: "#FFBA2F",
    },
  },
  shadows: Array(25).fill("none"),
});

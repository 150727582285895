import { Stack, Typography } from "@mui/material";
import React from "react";

const OrderSummaryItem = ({
  label,
  value,
  isAllDayPoints = false,
  points,
  isGrandTotal = false,
}) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography
        variant="adbody1"
        sx={{
          ...(isGrandTotal && { fontWeight: 700 }),
          lineHeight: "normal",
        }}
      >
        {label}
        {isAllDayPoints && (
          <span
            style={{
              color: "#BAF90B",
            }}
          >
            {" "}
            ({points})
          </span>
        )}
        :
      </Typography>
      <Typography
        variant="adbody1"
        sx={{
          ...(isGrandTotal && { fontWeight: 700 }),
          ...(isAllDayPoints && { color: "primary.main" }),
          lineHeight: "normal",
        }}
      >
        {`${isAllDayPoints ? "-" : ""}$ ${value}`}
      </Typography>
    </Stack>
  );
};

export default OrderSummaryItem;

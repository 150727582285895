import React from "react";
import { Modal, Fade, Box, IconButton, Backdrop } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./styles.css";

export default function DialogueBox({ open, setOpen, children, closeButton }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          setOpen(false);
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className="dialogue_container">
          <div className="dialogue_content">
            {/* CLOSE ICON */}
            {closeButton && (
              <div
                style={{
                  alignSelf: "end",
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                }}
              >
                <IconButton onClick={() => setOpen(false)}>
                  <CloseIcon sx={{ color: "#00000" }} />
                </IconButton>
              </div>
            )}
            <br />
            {children}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

import { createTheme } from "@mui/material";
import { themePalette } from "./colors";

export const themeTypography = createTheme(themePalette, {
  typography: {
    adh1: {
      fontFamily: '"AzoSans", "Helvetica", "Arial", sans-serif',
      fontStyle: "normal",
      fontSize: "32px",
      lineHeight: "41px",
    },
    adh2: {
      fontFamily: '"AzoSans", "Helvetica", "Arial", sans-serif',
      fontStyle: "normal",
      fontSize: "28px",
      lineHeight: "36px",
    },
    adh3: {
      fontFamily: '"AzoSans", "Helvetica", "Arial", sans-serif',
      fontStyle: "normal",
      fontSize: "24px",
      lineHeight: "30px",
    },
    adh4: {
      fontFamily: '"AzoSans", "Helvetica", "Arial", sans-serif',
      fontStyle: "normal",
      fontSize: "22px",
      lineHeight: "28px",
    },
    adh5: {
      fontFamily: '"AzoSans", "Helvetica", "Arial", sans-serif',
      fontStyle: "normal",
      fontSize: "20px",
      lineHeight: "25px",
    },
    adh6: {
      fontFamily: '"AzoSans", "Helvetica", "Arial", sans-serif',
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "23px",
    },
    adbody1: {
      fontFamily: '"AzoSans", "Helvetica", "Arial", sans-serif',
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "20px",
    },
    adbody2: {
      fontFamily: '"AzoSans", "Helvetica", "Arial", sans-serif',
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "17px",
    },
    adsubtitle: {
      fontFamily: '"AzoSans", "Helvetica", "Arial", sans-serif',
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "15px",
    },
    adsubtitle1: {
      fontFamily: '"AzoSans", "Helvetica", "Arial", sans-serif',
      fontStyle: "normal",
      fontSize: "11px",
      lineHeight: "13px",
    },
    adsubtitle2: {
      fontFamily: '"AzoSans", "Helvetica", "Arial", sans-serif',
      fontStyle: "normal",
      fontSize: "9.33px",
      lineHeight: "13px",
    },
    linear: {
      fontSize: "14px",
      backgroundcolor: "primary",
      backgroundImage: `linear-gradient(to right, #57EBDE, #C8FA3C)`,
      backgroundSize: "100%",
      backgroundRepeat: "repeat",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    linearLightTheme: {
      fontSize: "14px",
      backgroundcolor: "primary",
      backgroundImage: `linear-gradient(91.84deg, #6100FF -654.29%, #D300BE 644.24%);`,
      backgroundSize: "100%",
      backgroundRepeat: "repeat",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
  },
});

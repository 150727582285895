import DashBoardIcon from "../../assets/Icons/SidebarIcons/In-Active/Dashboard.svg";
import DashBoardIconActive from "../../assets/Icons/SidebarIcons/Active/Dashboard.svg";
import AdTrackingIcon from "../../assets/Icons/SidebarIcons/In-Active/AdTracking.svg";
import AdTrackingIconActive from "../../assets/Icons/SidebarIcons/Active/AdTracking.svg";
import TimeLimitIcon from "../../assets/Icons/SidebarIcons/In-Active/TimeLimit.svg";
import TimeLimitIconActive from "../../assets/Icons/SidebarIcons/Active/TimeLimit.svg";
import HelpDeskIcon from "../../assets/Icons/SidebarIcons/In-Active/HelpDesk.svg";
import HelpDeskIconActive from "../../assets/Icons/SidebarIcons/Active/HelpDesk.svg";
import ChangePasswordIcon from "../../assets/Icons/SidebarIcons/In-Active/ChangePassword.svg";
import ChangePasswordIconActive from "../../assets/Icons/SidebarIcons/Active/ChangePassword.svg";
import LogoutIcon from "../../assets/Icons/SidebarIcons/In-Active/Logout.svg";
import LogoutIconActive from "../../assets/Icons/SidebarIcons/Active/Logout.svg";
import RefundIcon from "../../assets/Icons/SidebarIcons/In-Active/refund.svg";
import RefundIconActive from "../../assets/Icons/SidebarIcons/Active/refund.svg";
import OrderListIcon from "../../assets/Icons/SidebarIcons/In-Active/OrderList.svg";
import OrderListIconActive from "../../assets/Icons/SidebarIcons/Active/OrderList.svg";
export const logout = {
  icon: LogoutIcon,
  activeIcon: LogoutIconActive,
  title: "Logout",
};
export const changePassword = {
  href: "/changePassword",
  icon: ChangePasswordIcon,
  activeIcon: ChangePasswordIconActive,
  title: "Change Password",
};

const dashboardItems = [
  {
    href: "/dashboard",
    icon: DashBoardIcon,
    activeIcon: DashBoardIconActive,
    title: "Dashboard",
  },
  {
    href: "/adTracking",
    icon: AdTrackingIcon,
    activeIcon: AdTrackingIconActive,
    title: "Ads Tracking",
  },
  {
    href: "/refund",
    icon: RefundIcon,
    activeIcon: RefundIconActive,
    title: "Refund Order",
  },
  {
    href: "/orderTracking",
    icon: OrderListIcon,
    activeIcon: OrderListIconActive,
    title: "Order Tracking",
  },
  {
    href: "/timeLimit",
    icon: TimeLimitIcon,
    activeIcon: TimeLimitIconActive,
    title: "Time Limit",
  },
  {
    href: "/helpDesk",
    icon: HelpDeskIcon,
    activeIcon: HelpDeskIconActive,
    title: "Help Desk",
  },
];
export default dashboardItems;

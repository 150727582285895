import { Box, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
const OrderDetailsDataBox = ({
  title,
  value,
  isLastSlide = false,
  isSummary = false,
}) => {
  const { isLoading } = useSelector((state) => state.orderList);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        height: "100%",
        borderRight: !isLastSlide ? 2 : 0,
        borderColor: "#615771",
        paddingRight: isSmallScreen ? 0 : 2,
      }}
    >
      <Stack direction="column">
        <Typography
          variant={isSummary ? "adh6" : "adbody2"}
          sx={{ lineHeight: "normal", fontWeight: 500 }}
          color={isSummary ? "primary.main" : "#B0B0B0"}
          mb={"18px"}
        >
          {title}
        </Typography>
        {!isLoading && (
          <Typography
            variant={isSummary ? "adbody1" : "adbody2"}
            sx={{
              lineHeight: isSummary ? "22px" : "normal",
              fontWeight: isSummary ? 400 : 500,
              ...(isSummary && {
                inlineSize: "200px",
                overflowWrap: "break-word",
              }),
            }}
            color="#FFFFFF"
          >
            {value}
          </Typography>
        )}
        {isLoading && <Skeleton variant="text" height="20px" width="60%" />}
      </Stack>
    </Box>
  );
};

export default OrderDetailsDataBox;

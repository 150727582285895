import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { fontFamily } from "../../constants/FontFamily";
import DialogueBox from "../DialogueBox";

const PopupModal = ({
  open,
  handleClose,
  icon,
  title,
  description,
  primaryBtnText,
  primaryBtnPress,
}) => {
  return (
    <DialogueBox open={open} setOpen={handleClose}>
      <Stack justifyContent="space-around" alignItems="center" spacing={3}>
        <Box component="img" src={icon} />
        <Stack spacing={1}>
          <Typography
            variant="adbody1"
            sx={{ lineHeight: "19.2px", fontWeight: 700, ...fontFamily.bold }}
            color="text.white"
          >
            {title}
          </Typography>
          <Typography
            variant="adbody2"
            sx={{ lineHeight: "16.8px", fontWeight: 400 }}
            color="text.white"
          >
            {description}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="space-around">
          <Button variant="contained" onClick={primaryBtnPress}>
            {primaryBtnText}
          </Button>
          <Button
            variant="nofill"
            sx={{
              color: "#FFFFFF",
            }}
            onClick={handleClose}
          >
            Go Back
          </Button>
        </Stack>
      </Stack>
    </DialogueBox>
  );
};

export default PopupModal;

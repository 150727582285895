import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import HealthySocialLogo from "../../assets/Illustrations/healthySocialHeightResolution.png";
import "./index.css";

const HealthySocialLight = () => {
  return (
    <Stack
      className="healthy-social-container"
      sx={{
        height: "100vh",
        width: "100vw",
        background: "#F2F2F2",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        className="lightWrapper"
      >
        <Box
          component="img"
          height="100%"
          width={{ sm: "50%", xs: "100%" }}
          src={HealthySocialLogo}
        />
      </Box>

      <ul styles={{ marginTop: "24px" }}>
        <li className="list__content__light">being social is human.</li>
        <li className="list__content__light">
          we created{" "}
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "25.6px",
              fontWeight: "400",
            }}
            variant="linearLightTheme"
          >
            dayo
          </Typography>{" "}
          to help create good habits and just the right amount of daily social
          media exposure.
        </li>
        <li className="list__content__light">
          the best part about it, now with{" "}
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "25.6px",
              fontWeight: "400",
            }}
            variant="linearLightTheme"
          >
            dayo
          </Typography>{" "}
          at your side, you get something in return for the time you spend
          scrolling!
        </li>
      </ul>
    </Stack>
  );
};

export default HealthySocialLight;

import { createTheme } from "@mui/material";
import { themePalette } from "./colors";

export const themeButtons = createTheme(themePalette, {
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "5px",
          padding: "16px 32px",
          textTransform: "none",
          fontFamily: '"AzoSans-Medium", "Helvetica", "Arial", sans-serif',
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "19px",
          gap: "16px",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            background: themePalette.palette.primary.buttonGradiant,
            color: themePalette.palette.text.white,
            "&:disabled": {
              background: themePalette.palette.primary.border,
              color: themePalette.palette.text.grey,
            },
          },
        },
      ],
    },
  },
});

import {
  Box,
  List,
  SwipeableDrawer as MuiSwipeableDrawer,
  Toolbar,
} from "@mui/material";
import Logo from "../../assets/Illustrations/Logo.svg";

import styled from "@emotion/styled";
import React from "react";
import dashboardItems from "./dashboardItems.js";
import SidebarNavItem from "./SidebarNavItem";
import SidebarFooter from "./SidebarFooter";
import "./index.css";
const Sidebar = ({ ...rest }) => {
  const Drawer = styled(MuiSwipeableDrawer)`
    border-right: 5px solid primary.border;

    > div {
      border-right: 5px solid primary.border;
    }
  `;
  return (
    <Drawer
      variant="permanent"
      sx={{
        borderRight: 3,
        borderRightColor: "primary.border",
      }}
      PaperProps={{
        style: {
          width: "290px",
          backgroundColor: "#1E0338",
          paddingTop: 20,
          paddingBottom: 20,
        },
        className: "paper-scroll",
      }}
      {...rest}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: 5,
        }}
      >
        <Box component="img" src={Logo} alt="Logo" />
      </Toolbar>
      <List sx={{ height: "70%" }}>
        {dashboardItems.map((item, index) => {
          return <SidebarNavItem key={index} item={item} {...rest} />;
        })}
      </List>
      <SidebarFooter />
    </Drawer>
  );
};

export default Sidebar;

import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { fontFamily } from "../../constants/FontFamily";
import { convertToLocalTime } from "../../services/stringConverter";
import ProductListSkeleton from "../Skeleton/ProductListSkeleton";
import ReactHtmlParser from "react-html-parser";
import NoImageFound from "../../assets/Illustrations/NoImageAvaiable.jpg";
const ProductListing = () => {
  const { isLoading, singleOrderDetails } = useSelector(
    (state) => state.orderList
  );
  const addDefaultSrc = (ev) => {
    ev.target.src = NoImageFound;
  };
  return (
    <Stack direction="column" spacing={2} marginTop="36px">
      {isLoading && <ProductListSkeleton />}
      {!isLoading &&
        singleOrderDetails?.line_items?.map((item, index) => {
          let productPrice = item?.product?.price ? +item?.product?.price : 0;
          return (
            <Fragment key={index}>
              <Divider hidden={index === 0} />
              <Stack
                direction="row"
                spacing={3}
                sx={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box
                  component="img"
                  onError={addDefaultSrc}
                  alt="Product Image"
                  src={
                    item?.product?.media_object
                      ? item?.product?.media_object[0]?.src
                      : NoImageFound
                  }
                  sx={{
                    height: "100%",
                    minHeight: "342px",
                    borderRadius: "5px",
                    maxWidth: "278px",
                  }}
                />
                <Stack
                  direction="column"
                  spacing={1.7}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="adh4"
                    color="text.primary"
                    sx={{
                      fontWeight: 500,
                      color: "white",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      lineHeight: "normal",
                      ...fontFamily.medium,
                    }}
                  >
                    {item?.product?.title}
                  </Typography>
                  <Typography
                    variant="adh6"
                    sx={{
                      fontWeight: 500,
                      color: "#A5D300",
                      lineHeight: "normal",
                      ...fontFamily.medium,
                    }}
                  >
                    Order Status
                  </Typography>
                  <Typography
                    variant="adbody1"
                    sx={{
                      textTransform: "lowercase",
                      ":first-letter": {
                        textTransform: "capitalize",
                      },
                    }}
                  >
                    {`${singleOrderDetails?.status.replaceAll("_", " ")} on ${
                      convertToLocalTime(singleOrderDetails?.updated_at) ??
                      "N/A"
                    }`}
                  </Typography>
                  <Typography
                    variant="adh6"
                    sx={{
                      fontWeight: 500,
                      color: "#A5D300",
                      lineHeight: "normal",
                      ...fontFamily.medium,
                    }}
                  >
                    Description
                  </Typography>
                  <Box
                    sx={{
                      maxHeight: "120px",
                      overflow: "auto",
                      "::-webkit-scrollbar": {
                        width: "8px",
                      },

                      "::-webkit-scrollbar-track": {
                        background: "transparent",
                      },
                      "::-webkit-scrollbar-thumb": {
                        backgroundColor: "white",
                        borderRadius: "20px",
                      },
                    }}
                  >
                    {ReactHtmlParser(item?.product?.description)}
                  </Box>
                  <Typography
                    variant="adh6"
                    sx={{
                      fontWeight: 500,
                      color: "#A5D300",
                      lineHeight: "normal",
                      ...fontFamily.medium,
                    }}
                  >
                    Price
                  </Typography>
                  <Typography variant="adbody1">{`$${productPrice.toFixed(
                    2
                  )}`}</Typography>
                </Stack>
              </Stack>
            </Fragment>
          );
        })}
    </Stack>
  );
};

export default ProductListing;

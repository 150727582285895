import { Stack, Typography } from "@mui/material";
import { width } from "@mui/system";
import React from "react";

const PageNotFound = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100vh",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
        }}
        variant="adh1"
        color="#ffffff"
      >
        Page not found please check the url!
      </Typography>
    </Stack>
  );
};

export default PageNotFound;

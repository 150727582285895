import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";

const OnBoardingSkeleton = () => {
  return (
    <Grid
      height="100vh"
      width="100%"
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item xl={5} lg={7}  mt={-15}>
        <Box>
          <Skeleton
            variant="rectangular"
            height="433px"
            width="420px"
            margin="auto"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default OnBoardingSkeleton;

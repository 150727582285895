import { Box, Stack } from "@mui/material";
import { ReactComponent as DownloadCsvIcon } from "../../assets/Icons/AdTracking/DownloadCSV.svg";
import React from "react";
import LinearButton from "../LinearButton";
import OrderDetailsDataBox from "./OrderDetailsDataBox";
import { useSelector } from "react-redux";
import { convertToLocalTime } from "../../services/stringConverter";

const OrderDetailsHeader = () => {
  const { singleOrderDetails } = useSelector((state) => state.orderList);
  const onClickDowload = (data) => {
    window.location.href = data;
  };
  return (
    <Stack
      marginTop="28px"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="row" spacing={5} alignItems="center">
        <OrderDetailsDataBox
          title="Order Placed"
          value={convertToLocalTime(singleOrderDetails?.created_at) ?? "N/A"}
        />
        <OrderDetailsDataBox
          title="Order ID"
          value={singleOrderDetails?.id ?? "N/A"}
        />
        <OrderDetailsDataBox
          title="Order Total"
          value={`$${singleOrderDetails?.cart_total}`}
          isLastSlide={true}
        />
      </Stack>
      <Box
        sx={{
          width: "100%",
          maxWidth: "176px",
        }}
      >
        <LinearButton
          icon={<DownloadCsvIcon />}
          height="55px"
          title="Download Invoice"
          onClick={(e) => {
            e.preventDefault();
            onClickDowload(singleOrderDetails.invoice_url);
          }}
          lineHeight="normal"
        />
      </Box>
    </Stack>
  );
};

export default OrderDetailsHeader;

import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import OrderDetailsDataBox from "./OrderDetailsDataBox";
import OrderSummaryItem from "./OrderSummaryItem";

const OrderSummary = () => {
  const { isLoading, singleOrderDetails } = useSelector(
    (state) => state.orderList
  );
  let shippingAddress = `${
    singleOrderDetails?.shipping_address?.address_line1
      ? singleOrderDetails?.shipping_address?.address_line1 + ","
      : " "
  }${
    singleOrderDetails?.shipping_address?.address_line2
      ? singleOrderDetails?.shipping_address?.address_line2
      : ""
  }${
    singleOrderDetails?.shipping_address?.city
      ? singleOrderDetails?.shipping_address?.city + ","
      : ""
  } ${
    singleOrderDetails?.shipping_address?.state
      ? singleOrderDetails?.shipping_address?.state + ","
      : ""
  } ${
    singleOrderDetails?.shipping_address?.country
      ? singleOrderDetails?.shipping_address?.country + ","
      : ""
  } ${
    singleOrderDetails?.shipping_address?.pincode
      ? singleOrderDetails?.shipping_address?.pincode + ","
      : ""
  } ${singleOrderDetails?.user?.phone_number ?? ""}`;
  return (
    <Grid
      container
      mt="24px"
      alignItems="center"
      className="linear__border"
      sx={{
        height: "100%",
        maxHeight: "220px",
      }}
    >
      <Grid item sm={4} md={4} sx={{ height: "100%" }}>
        <Box sx={{ paddingRight: 5, height: "100%" }}>
          <OrderDetailsDataBox
            title="Shipping Address"
            value={shippingAddress}
            isSummary={true}
          />
        </Box>
      </Grid>
      <Grid item sm={4} md={4} sx={{ height: "100%" }}>
        <Box sx={{ paddingRight: 5, height: "100%" }}>
          <OrderDetailsDataBox
            title="Payment Method"
            value={`Stripe`}
            isSummary={true}
          />
        </Box>
      </Grid>
      <Grid item sm={4} md={4}>
        <Stack
          direction="column"
          sx={{
            width: "100%",
            maxWidth: "264px",
          }}
        >
          <Typography
            variant={"adh6"}
            sx={{ lineHeight: "normal", fontWeight: 500 }}
            color={"primary.main"}
            mb={"18px"}
          >
            Order Summary
          </Typography>
          <Stack direction="column" spacing={1}>
            {!isLoading ? (
              <>
                <OrderSummaryItem
                  label="Item(S) Subtotal"
                  value={singleOrderDetails?.order_total ?? 0.0}
                />
                <OrderSummaryItem label="Shipping" value={"0.0"} />
                <OrderSummaryItem
                  label="Total"
                  value={singleOrderDetails?.order_total ?? 0.0}
                />
                {singleOrderDetails?.points_used > 0 ? (
                  <OrderSummaryItem
                    label="All Day Points"
                    value={
                      singleOrderDetails?.points_used
                        ? (singleOrderDetails?.points_used / 100).toFixed(2)
                        : "N/A"
                    }
                    isAllDayPoints={true}
                    points={singleOrderDetails?.points_used}
                  />
                ) : null}
                <OrderSummaryItem
                  label="Grand Total"
                  value={singleOrderDetails?.cart_total ?? 0.0}
                  isGrandTotal={true}
                />
              </>
            ) : (
              <Stack direction="column" spacing={1}>
                <Skeleton variant="text" height="20px" width="70%" />
                <Skeleton variant="text" height="20px" width="70%" />
                <Skeleton variant="text" height="20px" width="70%" />
                <Skeleton variant="text" height="20px" width="70%" />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default OrderSummary;

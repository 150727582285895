//Auth Slice For Login And Logout
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../config/axios";
import { GET_BRAND_LIST } from "../../../constants/URLS/urls";
import { PURGE } from "redux-persist";
import { defaultMessage } from "../../../constants/Messages";
import { HTTP_OK } from "../../../constants/StatusCode";

const initialState = {
  isLoading: false,
  nextPage: null,
  prevPage: null,
  count: 0,
  brands: [],
  totalPages: 0,
};

export const getSearchList = createAsyncThunk(
  "brandList/getSearch",
  async (url) => {
    try {
      const response = await axiosInstance.get(url);
      if (response.data.status_code !== HTTP_OK) {
        throw new Error(response.data.message[0]);
      }
      const resData = response.data.data[0];
      return {
        results: resData.results,
      };
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);

export const getList = createAsyncThunk(
  "brandList/get",
  async (fetchingUrl = null) => {
    try {
      let url = fetchingUrl !== null ? fetchingUrl : GET_BRAND_LIST;
      const response = await axiosInstance.get(url);
      if (response.data.status_code !== HTTP_OK) {
        throw new Error(response.data.message[0]);
      }
      const resData = response.data.data[0];
      return {
        results: resData.results,
        count: resData.count,
        totalPages: resData.links.total_pages,
        nextPage: resData.links.next,
        prevPage: resData.links.previous,
      };
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);

const brandListSlice = createSlice({
  name: "brandList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
    builder.addCase(getList.fulfilled, (state, action) => {
      state.brands.push(...action.payload.results);
      state.count = action.payload.count;
      state.nextPage = action.payload.nextPage;
      state.prevPage = action.payload.prevPage;
      state.totalPages = action.payload.totalPages;
    });
  },
});

export default brandListSlice.reducer;

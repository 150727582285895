const getFontObj = (family) => {
  return {
    fontFamily: family,
  };
};
export const fontFamily = {
  regular: getFontObj("AzoSans"),
  medium: getFontObj("AzoSans-Medium"),
  bold: getFontObj("AzoSans-Bold"),
  light: getFontObj("AzoSans-Light"),
  black: getFontObj("AzoSans-Black"),
  thin: getFontObj("AzoSans-Thin"),
};

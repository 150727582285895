import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as HealthySocialLogo } from "../../assets/Illustrations/HealthyScreen.svg";
import "./style.css";

const HealthySocial = () => {
  return (
    <Box className="healthy-social-dark-container">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <HealthySocialLogo />
      </Box>

      <ul styles={{ marginTop: "24px" }}>
        <li className="list__content">being social is human.</li>
        <li className="list__content">
          we created{" "}
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "25.6px",
              fontWeight: "400",
            }}
            variant="linear"
          >
            dayo
          </Typography>{" "}
          to help create good habits and just the right amount of daily social
          media exposure.
        </li>
        <li className="list__content">
          the best part about it, now with{" "}
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "25.6px",
              fontWeight: "400",
            }}
            variant="linear"
          >
            dayo
          </Typography>{" "}
          at your side, you get something in return for the time you spend
          scrolling!
        </li>
      </ul>
    </Box>
  );
};

export default HealthySocial;

//Auth Slice For Login And Logout
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { LOGIN_API } from "../../../constants/URLS/urls";
import { PURGE } from "redux-persist";
import { defaultMessage, emailNotFound } from "../../../constants/Messages";
import { HTTP_NOT_FOUND, HTTP_OK } from "../../../constants/StatusCode";

const initialState = {
  isLoggedIn: false,
  accessToken: null,
  refreshToken: null,
  email: null,
  isRefreshing: false,
};
//Calling Login Api
export const loginUser = createAsyncThunk("user/login", async (loginData) => {
  try {
    const response = await axios.post(LOGIN_API, loginData);
    if (response.data.status_code !== HTTP_OK) {
      if (response.data.status_code === HTTP_NOT_FOUND) {
        throw new Error(emailNotFound);
      }
      throw new Error(response.data.message[0]);
    }
    const resData = response.data.data;
    return {
      email: resData.email,
      accessToken: resData.token.access,
      refreshToken: resData.token.refresh,
    };
  } catch (error) {
    let message = defaultMessage;
    if (error?.response?.data?.message[0]) {
      message = error.response.data.message[0];
    } else {
      message = error.message;
    }
    throw message;
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateToken: (state, action) => {
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
    },
    updateIsRefreshing: (state, action) => {
      state.isRefreshing = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.email = action.payload.email;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    });
  },
});

export const { updateToken, updateIsRefreshing } = authSlice.actions;
export default authSlice.reducer;

import React from "react";
import { useDispatch, useStore } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import NetWrapper from "./components/NetWrapper";
import CustomSnackbar from "./components/Snackbar/index";
import { createInstanceAndInjectStore } from "./config/axios";
import AppRouter from "./routes";
function App() {
  const st = useStore();
  const dispatch = useDispatch();
  createInstanceAndInjectStore(st, dispatch);
  return (
    <BrowserRouter>
      <CustomSnackbar />
      <NetWrapper>
        <AppRouter />
      </NetWrapper>
    </BrowserRouter>
  );
}

export default App;

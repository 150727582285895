import { Box, Stack } from "@mui/material";
import { fontFamily } from "../../constants/FontFamily";
import Instagram from "../../assets/Illustrations/Socials/InstagramLight.svg";
import Facebook from "../../assets/Illustrations/Socials/FacebookLight.svg";
import Snapchat from "../../assets/Illustrations/Socials/SnapchatLight.svg";
import Twitter from "../../assets/Illustrations/Socials/TwitterLight.svg";
import TikTok from "../../assets/Illustrations/Socials/TiktokLight.svg";
import YouTube from "../../assets/Illustrations/Socials/YoutubeLight.svg";
import "./index.css";
const AboutAllDayLight = () => {
  return (
    <Box
      className="about-allday-container"
      sx={{
        height: "100vh",
        width: "100vw",
        overflowX: "hidden",
        overflowY: "auto",
        background: "#f2f2f2",
      }}
    >
      <Box
        sx={{
          marginTop: "40px",
        }}
      >
        <Box component="p" className="aboutContentLight">
          dayo is the first and only platform that rewards you for having a
          healthy relationship with social media.
        </Box>
        <Box component="p" className="aboutContentLight">
          people are the product of social media companies, without the people,
          their business has little value. yet, the majority of people on social
          media have nothing to show for the time and effort spent.
        </Box>
        <Box component="p" className="aboutContentLight">
          this is why we created dayo : we celebrate time as a finite and
          valuable asset. we empower and reward our users with points for
          healthy habits that can be traded in for products, services, exclusive
          discounts, nfts and crypto in our marketplace.
        </Box>
        <Box component="p" className="aboutContentLight">
          we partner with like-minded companies to get people out into the world
          to enjoy screen-free experiences and make memories.
        </Box>
        <Box
          component="p"
          className="aboutContentLight"
          sx={{
            marginTop: "25px",
            fontSize: "16px",
            fontWeight: 500,
            ...fontFamily.medium,
          }}
        >
          follow us on:
        </Box>
        <Stack
          direction="row"
          spacing={1}
          className="aboutContentLight"
          alignItems="center"
          marginBottom={4}
        >
          <a className="links" target="_blank" href="https://instagram.com">
            <Box width="40px" component="img" src={Instagram} />
          </a>
          <a className="links" target="_blank" href="https://facebook.com">
            <Box width="40px" component="img" src={Facebook} />
          </a>
          <a className="links" target="_blank" href="https://snapchat.com">
            <Box width="40px" component="img" src={Snapchat} />
          </a>
          <a className="links" target="_blank" href="https://tiktok.com">
            <Box width="40px" component="img" src={TikTok} />
          </a>
          <a className="links" target="_blank" href="https://twitter.com">
            <Box width="40px" component="img" src={Twitter} />
          </a>
          <a className="links" target="_blank" href="https://youtube.com">
            <Box width="40px" component="img" src={YouTube} />
          </a>
        </Stack>
      </Box>
    </Box>
  );
};

export default AboutAllDayLight;

//Auth Slice For Login And Logout
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../config/axios";
import {
  GET_DASHBOARD_DATA,
  GET_EXPENDITURE_DATA,
  GET_REVENUE_DATA,
} from "../../../constants/URLS/urls";
import { PURGE } from "redux-persist";
import { dataNotFound, defaultMessage } from "../../../constants/Messages";
import { HTTP_NOT_FOUND, HTTP_OK } from "../../../constants/StatusCode";

const initialState = {
  isLoading: true,
  isRevenueGraphLoading: true,
  isExpendiureGraphLoading: true,
  stats: {},
  todaysStat: {},
  salesCategory: {},
  pointsStat: {},
  expenditureGraph: [],
  revenueGraph: {},
};
// Calling Get Expenditure Graph data API
export const getExpenditureGraphData = createAsyncThunk(
  "get/expenditureGraphData",
  async () => {
    try {
      const response = await axiosInstance.post(GET_EXPENDITURE_DATA);
      if (response.data.status_code !== HTTP_OK) {
        if (response.data.status_code === HTTP_NOT_FOUND) {
          throw new Error(dataNotFound);
        }
        throw new Error(response.data.message[0]);
      }
      const resData = response.data.data;
      return {
        expenditureGraph: resData,
      };
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);
//Calling Get Revenue Graph data API
export const getRevenueGraphData = createAsyncThunk(
  "get/revenueGraphData",
  async (params) => {
    try {
      const response = await axiosInstance.post(GET_REVENUE_DATA, params);
      if (response.data.status_code !== HTTP_OK) {
        if (response.data.status_code === HTTP_NOT_FOUND) {
          throw new Error(dataNotFound);
        }
        throw new Error(response.data.message[0]);
      }
      const resData = response.data.data;
      return {
        revenueGraph: resData,
      };
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);

export const getDashboardData = createAsyncThunk("get/dashboard", async () => {
  try {
    const response = await axiosInstance.post(GET_DASHBOARD_DATA);
    if (response.data.status_code !== HTTP_OK) {
      if (response.data.status_code === HTTP_NOT_FOUND) {
        throw new Error(dataNotFound);
      }
      throw new Error(response.data.message[0]);
    }
    const resData = response.data.data;
    return {
      stats: {
        near_to_points: resData.near_to_points,
        points_in_cash: resData.points_in_cash,
        premium_user: resData.premium_user,
        total_commission: resData.total_commission,
        total_order: resData.total_order,
        total_product: resData.total_product,
        total_sales: resData.total_sales,
        total_users: resData.total_users,
      },
      todayStats: resData.today_stat,
      pointsStat: resData.points_stat,
      salesByCategory: resData.sales_category,
    };
  } catch (error) {
    let message = defaultMessage;
    if (error?.response?.data?.message[0]) {
      message = error.response.data.message[0];
    } else {
      message = error.message;
    }
    throw message;
  }
});

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
    builder.addCase(getDashboardData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDashboardData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.stats = action.payload.stats;
      state.todaysStat = action.payload.todayStats;
      state.salesCategory = action.payload.salesByCategory;
      state.pointsStat = action.payload.pointsStat;
    });
    builder.addCase(getDashboardData.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getRevenueGraphData.pending, (state, action) => {
      state.isRevenueGraphLoading = true;
    });
    builder.addCase(getRevenueGraphData.fulfilled, (state, action) => {
      state.isRevenueGraphLoading = false;
      state.revenueGraph = action.payload.revenueGraph;
    });
    builder.addCase(getRevenueGraphData.rejected, (state, action) => {
      state.isRevenueGraphLoading = false;
      state.revenueGraph = null;
    });
    builder.addCase(getExpenditureGraphData.pending, (state, action) => {
      state.isExpendiureGraphLoading = true;
    });
    builder.addCase(getExpenditureGraphData.fulfilled, (state, action) => {
      state.isExpendiureGraphLoading = false;
      state.expenditureGraph = action.payload.expenditureGraph;
    });
    builder.addCase(getExpenditureGraphData.rejected, (state, action) => {
      state.isExpendiureGraphLoading = false;
      state.expenditureGraph = [];
    });
  },
});

export default dashboardSlice.reducer;

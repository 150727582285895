import OnBoardingSkeleton from "../components/Skeleton/OnBoardingSkeleton";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import AppLayout from "../Layout/Dashboard";
import AuthWrapper from "../components/AuthWrapper";
import { routing, otherRoute } from "./router";
import LayoutSkeleton from "../components/Skeleton/LayoutSkeleton";
import PageNotFound from "../pages/404";
const LazyHomePage = lazy(() => import("../pages/HomePage"));

function AppRouter(props) {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<OnBoardingSkeleton />}>
            <AuthWrapper required={false}>
              <LazyHomePage />
            </AuthWrapper>
          </Suspense>
        }
      />
      {routing.map((route, index) => {
        return (
          <Route
            key={index}
            exact
            path={route.href}
            element={
              <Suspense fallback={<LayoutSkeleton />}>
                <AuthWrapper required={true}>
                  <AppLayout title={route.title} hasBack={route?.hasBack}>
                    {route.component}
                  </AppLayout>
                </AuthWrapper>
              </Suspense>
            }
          />
        );
      })}

      {otherRoute.map((route, index) => {
        return (
          <Route
            key={index}
            exact
            path={route.href}
            element={route.component}
          />
        );
      })}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default AppRouter;

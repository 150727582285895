import { Box, Skeleton } from "@mui/material";
import React from "react";

const LayoutSkeleton = () => {
  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="flex-start">
        <Box display={{ lg: "block", xs: "none" }} height="100vh" width={280}>
          <Skeleton variant="rectangular" height="100%" width="100%" />
        </Box>
        <Box height="100px" width="100vw">
          <Skeleton variant="rectangular" height="100%" width="100%" />
        </Box>
      </Box>
    </>
  );
};

export default LayoutSkeleton;

import { Paper as MuiPaper, Toolbar } from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import Navigation from "./Navigation";

export default function AppLayout({ children, title, hasBack }) {
  const Root = styled.div`
    display: flex;
    min-height: 100vh;
  `;
  const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
  `;

  const Paper = styled(MuiPaper)(spacing);

  const MainContent = styled(Paper)`
    flex: 1;
    background: transparent;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex: none;
    }

    .MuiPaper-root .MuiPaper-root {
      box-shadow: none;
    }
  `;
  return (
    <Root>
      <Navigation title={title} hasBack={hasBack} />
      <AppContent>
        <Toolbar sx={{ p: 2 }} />
        <MainContent p={5}>{children}</MainContent>
      </AppContent>
    </Root>
  );
}

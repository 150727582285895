export const HOMEPAGE_ROUTE = "/";
export const DASHBOARD_ROUTE = "/dashboard";
export const ADTRACKING = "/adTracking";
export const REFUND = "/refund";
export const ORDERTRACKING = "/orderTracking";
export const CREATEAD = "/createAd";
export const EDITAD = "/editAd/:adId";
export const TIMELIMIT = "/timeLimit";
export const HELPDESK = "/helpDesk";
export const CHANGEPASSWORD = "/changePassword";
export const TERMANDCONDITION = "/termAndCondition";
export const PRIVACYPOLICY = "/privacyPolicy";
export const VIEWAD = "/viewAd/:adId";
export const HEALTHSOCIAL = "/healthySocialMedia";
export const REDIRECTION = "/redirection";
export const HEALTHYSOCIALMEDIALIGHT = "/healthySocialMediaLight";
export const TERMANDCONDITIONLIGHT = "/termAndConditionLight";
export const PRIVACYPOLICYLIGHT = "/privacyPolicyLight";
export const REDIRECTIONLIGHT = "/redirectionLight";
export const ABOUTALLDAY = "/aboutAllDay";
export const ABOUTALLDAYLIGHT = "/aboutAllDayLight";
export const ORDERDETAILS = "/orderDetails";

import { Box } from "@mui/material";
import React from "react";
import "./lightTheme.css";

const Policy = () => {
  return (
    <Box
      className="policy-container"
      sx={{
        height: "100vh",
        width: "100vw",
        overflowX: "hidden",
        overflowY: "auto",
        background: "#f2f2f2",
      }}
    >
      <p className="content">
        This Privacy Policy applies to your use of the dayo mobile application
        (the “dayo App”) which hosts the dayo Marketplace (“Marketplace”) and
        uses the dayo reward point (“dayo(s)”), any website operated by dayo
        (including http://dayo.co) (the “dayo Website(s)”) and the services we
        provide that are accessible via any of them (the “Services”). This
        Privacy Policy sets out the basis on which any personal data we collect
        from you, or that you provide to us, will be stored and processed by us.
        <br />
        <br />
      </p>

      <p className="header">INFORMATION WE MAY COLLECT FROM YOU</p>
      <br />
      <p className="content">
        From time to time, we may collect or ask you to provide personal
        information including (without limitation) the following: your name,
        mobile phone number, email address, password, identification
        credentials, your contacts, biographical details, photographs and/or
        payment information.
        <br />
        <br />
        We also automatically collect, store and use information about your use
        of the Services, and about your computer, tablet, mobile or other device
        through which you access the Services. This includes the following
        information:
        <br />
        <br />
      </p>

      <ul>
        <li className="content">
          Technical Data: including the Internet protocol (IP) address, browser
          type, internet service provider, device identifiers, your login
          information, time zone setting, browser plug-in types and versions,
          preferred language, activities, operating system and platform, and
          geographical location; and
        </li>
        <br />

        <li className="content">
          Usage Data: including the full Uniform Resource Locators (URL),
          clickstream to, through and from the dayo Websites, pages you viewed
          and searched for, page response times, length of visits to certain
          pages, referral source/exit pages, page interaction information (such
          as scrolling, clicks and mouse-overs), date and time pages are
          accessed, website navigation and search terms used, and whether you
          have opened our marketing newsletters.
        </li>
      </ul>

      <p className="content">
        We may also automatically record the purchases you have made through the
        dayo App or dayo Websites using dayo (“Transaction Data”) to obtain an
        understanding of your preferences so that we can provide you with more
        tailored marketing where appropriate.
        <br />
        <br />
        As part of the Services, we may provide functionality allowing you to
        search for friends by using your Facebook credentials and, when you
        elect to do this, you will be asked to allow the dayo App to access
        certain information associated with your Facebook account such as your
        name, profile picture, gender and list of friends. Provided you consent
        to this, such information will be processed by us in order to identify
        your Facebook friends who are users of dayo App and to allow you to
        invite those Facebook friends to install the dayo App.
        <br />
        <br />
        Please note that, with your consent, we will collect information about
        your location and physical movements in order for the dayo App to
        function properly and monitor and verify forms of eligible movement. You
        may turn location monitoring on and off from time to time using the
        settings of your operating system of your mobile device but, if you
        disable this functionality, we will not be able to collect information
        relating to your step-count and GPS/Cell-ID location which will prevent
        tracking and/or conversion of your movement into dayos.
        <br />
        <br />
        We work with third parties from time to time (including, for example,
        Apple HealthKit, Near Foundation, business partners, sub-contractors in
        technical, payment and delivery services, advertising networks,
        analytics providers and search information providers who may provide to
        us information about you. This may include your purchase history from
        business partners who supply you with rewards made available via the
        dayo App.
        <br />
        <br />
      </p>

      <p className="header">USES MADE OF YOUR PERSONAL DATA</p>
      <br />
      <br />
      <p className="content">
        As a data controller, we will only use your personal data if we have a
        legal basis for doing so. The table at Annex 1 sets out the purposes for
        which we use your personal data as well as the relevant legal bases on
        which we do so.
        <br />
        <br />
      </p>

      <p className="header">WHO WE SHARE YOUR PERSONAL DATA WITH</p>
      <br />
      <br />
      <p className="content">
        We may share your personal data with the following recipients as
        necessary to achieve the purposes set out in Annex 1 or as otherwise
        described below:
        <br />
        <br />
      </p>

      <ul>
        <li className="content">
          Other users. Certain information of your personal data may be shared
          with other users of the dayo App as part of the normal operation of
          the Services (for example your uploaded profile picture and
          biographical information will be accessible to all users, and we may
          publish your total verified steps, or other movement, during a
          particular period to other users from time to time).
        </li>

        <li className="content">
          Service providers. We may share your personal data with third party
          service providers that perform services for us or on our behalf, which
          may include providing data hosting, customer relationship management,
          payment processing and analytics services.
        </li>

        <li className="content">
          Business partners. We may share your personal data with our trusted
          business partners in order that they can contact you from time to time
          with offers that may interest you and/or inform you of other products
          or services, provided you have given your consent for us to do so.
        </li>

        <li className="content">
          Law enforcement, regulators, governmental authorities and other
          parties for legal reasons. We may share your personal data with third
          parties if we are legally required to do so, or if we believe, in good
          faith, that such disclosure is necessary to comply with a legal
          obligation or request, to enforce our terms and conditions, to prevent
          or resolve security or technical issues, or to protect the rights,
          property or our safety, or the safety of our users, a third party, or
          the public.
        </li>

        <li className="content">
          Purchasers and third parties in connection with a business
          transaction. If we are involved in a merger, acquisition, bankruptcy,
          reorganization, partnership, asset sale or other transaction, we may
          disclose your personal data as part of that transaction.
        </li>

        <li className="content">
          Other members of the dayo group. We share personal data about you with
          other members of the dayo group in accordance with the uses set out in
          Annex 1.
        </li>
      </ul>

      <p className="content">
        In addition, we may share your personal data with other third parties if
        you have provided your consent for us to do so.
        <br />
        <br />
      </p>

      <p className="header">INTERNATIONAL TRANSFERS</p>
      <br />
      <br />
      <p className="content">
        We may transfer your personal data outside of the country where you are
        located, for example, if we are using a service provider based in
        another country where our group members are located.
        <br />
        <br />
        If you are located in the UK or EEA, your personal data may be
        transferred to countries which do not provide the same level of
        protection for personal data as that provided for under UK and EEA law.
        <br />
        <br />
        Where we transfer your personal data to a country which is not
        recognised by the UK government or EU Commission (as applicable) as
        ensuring an adequate level or protection for personal data, we will
        ensure that relevant safeguards are in place to ensure the adequate
        protection for your personal data (for example, by entering into
        standard contractual clauses with the recipients of your personal data).
        <br />
        <br />
        Further details regarding the relevant safeguards we implement can be
        obtained from us on request at heyo@dayo.co with the subject
        ‘international transfers’.
        <br />
        <br />
      </p>

      <p className="header">DATA RETENTION</p>
      <br />
      <br />
      <p className="content">
        Your personal data will be kept only for as long as is necessary to
        fulfill the purposes set out in this policy, for as long as we are
        required to do so by law or any regulatory obligation.
        <br />
        <br />
        To determine the appropriate retention period for personal data, we
        consider the amount, nature and sensitivity of the personal data, the
        potential risk of harm from unauthorized use or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal, regulatory, tax, accounting or other requirements.
        <br />
        <br />
      </p>

      <p className="header">DATA SECURITY</p>
      <br />
      <br />
      <p className="content">
        We take reasonable industry-standard care in keeping all our data secure
        and in preventing any unauthorized access or unlawful use of it.
        <br />
        <br />
      </p>

      <p className="header">COOKIES</p>
      <br />
      <br />
      <p className="content">
        We use cookies and similar technologies on the dayo App and dayo
        Websites for various purposes, including analytics and advertising. For
        more information regarding our use of cookies, please refer to Annex 2.
        <br />
        <br />
      </p>

      <p className="header">YOUR RIGHTS</p>
      <br />
      <br />
      <p className="content">
        Depending upon where you are located, you may have the following rights
        in respect of your personal data under applicable data protection law:
        <br />
        <br />
      </p>

      <ol>
        <li className="content">
          To ask us not to process your personal information for marketing
          purposes. You can exercise your right to prevent such processing (i.e.
          “opt out”) by clicking ‘unsubscribe’ at the bottom of our marketing
          emails or by emailing heyo@dayo.co, with the subject ‘unsubscribe’.
        </li>

        <li className="content">
          {`To change your personal information we hold about you. You can exercise your right by accessing your Account information in our app (Open dayo app -> Your Account Screen -> Edit (on Android) or Pencil icon (on iPhone) -> Change your details -> Save).`}
        </li>

        <li className="content">
          To ask us about the personal information we hold about you and to
          request a copy of your personal information. You can exercise your
          right to access this information by emailing heyo@dayo.co, with the
          subject ‘data access request’.
        </li>

        <li className="content">
          {`To delete your account and any personal information we hold on you. You can exercise your right to delete your dayo account and the personal information attached to it yourself, by triggering an account deletion via the Help section within the dayo app (Open dayo app -> Your Account Screen -> Settings -> Help -> Contact us -> Choose your problem = Delete Account).`}
        </li>

        <li className="content">
          To object to any use of your personal data that we carry out on the
          basis of our legitimate interests (as set out in Annex 1), subject to
          certain conditions.
        </li>

        <li className="content">
          To receive a copy of the personal data you have provided to us in a
          structured, commonly used, machine-readable format that supports
          re-use, or to request the transfer of your personal data to another
          person, in each case subject to certain conditions.
        </li>

        <li className="content">
          To withdraw consent to our processing of your personal data if this is
          based on your consent (as set out in Annex 1).
        </li>

        <li className="content">
          To require us to limit the purposes for which we process your personal
          data if the continued processing of the personal data in this way is
          not justified, such as where the accuracy of the data is contested by
          you.
        </li>
      </ol>

      <p className="content">
        Please note that the above rights are not absolute and we may be
        entitled to refuse requests, wholly or partly, where exceptions under
        applicable law apply.
        <br />
        <br />
        If you wish to exercise one of these rights, please follow the steps set
        out above or contract us using the details set out below.
        <br />
        <br />
      </p>

      <p className="header">COMPLAINTS</p>
      <br />
      <br />
      <p className="content">
        If you wish to lodge a complaint about how we process your personal
        data, please contact us using the details set out below. We will
        endeavor to respond to your complaint as soon as possible.
        <br />
        <br />
      </p>

      <p className="header">LINKS</p>
      <br />
      <br />
      <p className="content">
        The Services may contain features or links to websites and services
        provided by third parties. Any information you provide on third-party
        websites or services is provided directly to the operators of such
        websites or services and is subject to those operators’ policies
        governing privacy and security, even if accessed via the dayo App or
        dayo Websites. We are not responsible for the content or privacy and
        security practices and policies of third-parties to which links or
        access are provided through the dayo App or dayo Websites. We encourage
        you to learn about third parties’ privacy and security policies before
        providing them with your personal data.
        <br />
        <br />
      </p>

      <p className="header">CHANGES TO THIS PRIVACY POLICY</p>
      <br />
      <br />
      <p className="content">
        We reserve the right to change this Privacy Policy at any time. Any such
        changes we may make to this Privacy Policy will be posted on the dayo
        App and dayo Website(s), and may be emailed to you. Please check the
        Privacy Policy available on the dayo App and dayo Website(s) from time
        to time.
        <br />
        <br />
      </p>

      <p className="header">CONTACT</p>
      <br />
      <br />
      <p className="content">
        Questions, comments and requests regarding this Privacy Policy are
        welcomed and should be addressed to heyo@dayo.co with subject line
        “enquiry”.
        <br />
        <br />
      </p>

      <p className="header">
        ANNEX 1 – PURPOSES OF COLLECTING PERSONAL INFORMATION
      </p>
      <br />
      <br />

      <p className="content">
        The dayo App’s use and transfer to any other app of information received
        from Google APIs will adhere to Google API Services User Data Policy,
        including the Limited Use requirements.
        <br />
        <br />
        We use the following categories of personal data in the manner specified
        below, and rely on the following legal basis for processing:
        <br />
        <br />
        Contact Details (phone number): We use this data to verify your identity
        as part of our account set up procedure. Our legal basis for processing
        this data is because it is in our and your legitimate interests to
        ensure that you have used the correct phone number when signing up for
        the Services.
        <br />
        <br />
        Contact Details (email address, phone number etc.), profile information
        (username, profile photo, bio etc.), movement data (motion data,
        HealthKit data): We use this data to provide the Services, including
        operating the dayo Marketplace (using our own systems and those of
        appropriate third party service providers). Our legal basis for
        processing this data is based on performance of a contract (our terms
        and conditions).
        <br />
        <br />
        Location Data (iOS only): We use this data to verify your physical
        movement and location and issue dayos on the basis of this verified
        data. Our legal basis for processing this data is based on consent
        (where we are processing location data) and performance of a contract.
        <br />
        <br />
        Movement data (Healthkit / Google Fit steps data): We use this data to
        verify your physical movement and issue dayos on the basis of this
        verified data. Our legal basis for processing this data is based on
        consent and performance of a contract.
        <br />
        <br />
        Contact Details (email address, phone number etc.), payment details
        (card number, CVC etc.), profile information (username): We use this
        data to process your transactions with us. Our legal basis for
        processing this data is based on performance of a contract.
        <br />
        <br />
        Profile Information (username, profile photo etc.), time on social media
        data : We use this data to create daily leader boards of users,
        comprising all users or users meeting particular criteria as ranked by
        the number of minutes completed or using other criteria. Our legal basis
        for processing this data is because it is in our legitimate interests to
        make our services social and competitive and allow you to see how you
        rank against other users and encourage you to use less social media.
        <br />
        <br />
        Contact Details (phone number): We use this data to connect you with
        other users and allow you to invite contacts to use the Services. Our
        legal basis for processing this data is because it is in our legitimate
        interests to make our services social and allow you to interact with
        friends when using the Services.
        <br />
        <br />
        Contact Details (phone number, email address etc.), communications data
        (personal data composed in your requests, survey responses, complaints
        etc.): We use this data to respond to queries and complaints and provide
        you with information and materials that you request from us. We also use
        this data to communicate with you, including to inform you of updates to
        the dayo App, dayo Website(s), our Terms of Use and/or this Privacy
        Policy. We also use this data to perform market and customer research.
        Our legal basis for processing this data is because it is in our
        legitimate interests to respond to your queries and provide any
        information and materials requested in order to maintain good customer
        relations; it is in our legitimate interests to ensure that any changes
        to our policies, terms and other such technical updates are communicated
        to you; and because it is in our legitimate interests to carry out
        market and customer research so that we can improve our Services.
        <br />
        <br />
        Transaction Data and Order History: We use this data to obtain an
        understanding of your preferences and to maintain accounts and records.
        Our legal basis for processing this data is because it is in our
        legitimate interests to understand our users’ preferences so we can
        improve the Services and the offers we display on the dayo App; and
        because it is in compliance with a legal obligation.
        <br />
        <br />
        Contact Details (phone number, email address, social media accounts),
        marketing preferences (records of consents): We use this data for
        Marketing and advertising (including sending you marketing emails,
        carrying out online behavioral advertising and measuring the
        effectiveness of our marketing). We also use this data to share your
        contact details with trusted business partners for marketing purposes.
        Our legal basis for processing this data is consent (if required under
        applicable law). Where consent is not required under applicable law,
        such processing is necessary in our legitimate interests, namely to
        develop and grow our business.
        <br />
        <br />
        Profile Information (username, profile photo, bio etc.), usage data,
        transaction Data, movement data (motion data, HealthKit data, Google Fit
        data): We use this data to investigate and/or prevent suspected fraud or
        other criminal activities, to investigate disputes between users, and
        for Statistical analysis to help us manage our business, e.g. in
        relation to our financial performance, customer base, product range or
        other efficiency measures. Our legal basis for processing this data is
        because it is in our legitimate interests to resolve and protect ourself
        and users of the Services, the dayo App and the dayo Websites against
        harmful activities; it is in our legitimate interests to ensure that
        disputes between users are resolved and appropriate action is taken
        against users breaching the rules; and because it is in our legitimate
        interests to be as efficient as we can so we can deliver the best
        services to you.
        <br />
        <br />
        Profile Information (username, profile photo, bio etc.), usage data,
        technical data): We use this data to correct errors and problems with
        the Services, and to protect the security of systems and data. Our legal
        basis for processing this data is because it is in our legitimate
        interests to monitor the Services to ensure that it functions properly
        and is secure; to comply with our legal and regulatory obligations; and
        because it is also in our legitimate interests to protect systems and
        data and to prevent and detect criminal activity that could be damaging
        for you and/or us.
        <br />
        <br />
        Technical Data and Usage Data: We use this data to analyze the usage of
        the Services, including for the purposes of improving the Services and
        to ensure that content is presented in the most effective manner for
        you. Our legal basis for processing this data is based on consent.
        <br />
        <br />
        Contact Details (email address, phone number etc.), profile information
        (username, profile photo, bio etc.): We use this data to enforce legal
        rights or defend or undertake legal proceedings. Our legal basis for
        processing this data is because it is in our legitimate interests or
        those of a third party, i.e. to protect our business, interests and
        rights or those of others.
        <br />
        <br />
        <br />
        <br />
      </p>

      <p className="header">ANNEX 2 – COOKIES</p>
      <br />
      <br />
      <p style={{ fontStyle: "italic" }} className="content">
        What are cookies
      </p>
      <br />
      <br />

      <p className="content">
        We use cookies to distinguish you from other users of the dayo App and
        dayo Website(s) and obtain certain information about your usage and
        behavior. A cookie is a small file of letters and numbers that we put on
        your computer when you use the dayo App or dayo Website(s). This helps
        us to provide you with a better experience when you use the dayo App or
        browse the dayo Website(s) and also allows us to improve the Services.
        We will use both persistent cookies, which could remain on your device
        until their expiration (which, in some cases, is up to 10 years), and
        session cookies, which are temporary files removed from your device once
        your browser is closed.
        <br />
        <br />
      </p>
      <p style={{ fontStyle: "italic" }} className="content">
        Cookies we use
      </p>
      <br />
      <br />
      <p className="content">The types of cookies we may use include:</p>
      <br />
      <br />
      <ul>
        <li className="content">
          Strictly Necessary Cookies, which are required for the operation of
          the dayo App and dayo Websites. They include, for example, cookies
          that enable you to log into secure areas of our websites;
        </li>

        <li className="content">
          Analytical/Tracking Cookies, which allow us to recognise and count the
          number of visitors and analyze use of the Services, as well as to
          verify transactions; and
        </li>

        <li className="content">
          Advertising and Retargeting Cookies, which allow us to generate
          appropriate advertising directed to you on the dayo Website(s) as well
          as on the dayo App and other third party websites.
        </li>
      </ul>

      <br />
      <p className="content">
        Please note that third parties (including, for example, advertising
        networks and providers of external services like web traffic analysis
        services) may also use cookies, over which we have no control. These
        cookies are likely to be analytical/performance cookies or targeting
        cookies. You may block cookies by activating the appropriate setting on
        your browser but, if you do so, you may not be able to use all
        functionalities of the Services.
        <br />
        <br />
        We use a number of industry-standard data analytics tools, such as
        Google Analytics and Facebook Analytics. These collect certain
        information about you, such as your device’s IP address and browsing and
        usage behavior, and are used to allow us to track and monitor the
        traffic visiting the dayo App and dayo Website(s).
        <br />
        <br />
        <span style={{ fontStyle: "italic" }}>Consent to use cookies</span>
        <br />
        <br />
        We will ask for your permission (consent) to place cookies on your
        device when using the dayo Websites, except where these are essential
        for us to provide you with a service that you have requested.
        <br />
        <br />
        There is a notice on our home page which describes how we use cookies
        and requests your consent before we place any non-essential cookies on
        your device.
        <br />
        <br />
        <span style={{ fontStyle: "italic" }}>How to turn off cookies</span>
        <br />
        <br />
        If you do not want to accept cookies, you can change your browser
        settings so that cookies are not accepted. If you do this, please be
        aware that you may lose some of the functionality of our websites.
        <br />
        <br />
        To find out more about cookies, including how to see which cookies have
        been set and how to manage and delete them, you can visit the third
        party website:
        <a
          href="www.allaboutcookies.org"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          {" "}
          www.allaboutcookies.org
        </a>
      </p>
    </Box>
  );
};
export default Policy;

import { Box } from "@mui/material";
import React from "react";
import "./lightTheme.css";

const TermAndConditionLight = () => {
  return (
    <Box className="containerLight">
      <p className="header">1. INTRODUCTION</p>
      <p className="content">
        These Terms of Use (the “Terms”) tell you the terms on which you may
        make use of:
      </p>
      <br />
      <p className="content">• our website http://dayo.co (the “Site”) and</p>
      <p className="content">• our mobile app called dayo (the “App”),</p>
      <p className="content">
        and the contents and services available through them, as updated or
        added to from time to time.
        <br />
        <br />
        these Terms, we refer to our Site and the App collectively as the
        “Service”. The Service is owned and operated by dayol people llc, a
        company incorporated in Portland, Oregon USA.
        <br />
        <br />
        Please read these Terms carefully before you start to use the Service,
        as they set out the legal agreement between dayo and you for your use of
        the Service. By downloading, installing, using or accessing the Service,
        you confirm that you accept these Terms and that you agree to comply
        with them. If you do not agree to these Terms, you must not use the
        Service.
        <br />
        These Terms set out important information regarding your rights and
        obligations in connection with theService. In particular, we draw your
        attention to:
        <br />
        <br />
      </p>

      <p className="content">
        • the possible deduction of points from your dayo Account by way of dayo
        charges (Section 9 below); and
        <br />
        <br />• the sections that describe the limits on our liability to you
        contained in Sections 17, 19 and 21 below.
        <br />
        <br />
      </p>

      <p className="header">2. YOUR PRIVACY</p>
      <br />
      <p className="content">
        We only use any personal data we collect through your use of the Service
        in the ways set out in our privacy policy, unless you are using a
        specific service to which a different privacy policy applies, as set out
        below in Section 3.
        <br />
        <br />
        Please be aware that internet transmissions are never completely private
        or secure and that any message or information you send using the Service
        may be read or intercepted by others, even if there is a special notice
        that a particular transmission is encrypted.
        <br />
        <br />
      </p>

      <p className="header">3. ADDITIONAL TERMS FOR SPECIFIC SERVICES</p>
      <br />
      <p className="content">
        In addition, the services set out below will be governed by the
        following terms of use and privacy policies:
        <br />
        <br />
        Service | Web address of terms of use | Web address of privacy policy
        <br />
        Apple App Store | See Attachment 1 |
        {/* <a href="https://www.apple.com/legal/privacy/en-ww/" target="_blank">
          https://www.apple.com/legal/privacy/en-ww/
        </a> */}
        <br />
      </p>
      <p className="content">
        In-App Purchases |{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.apple.com/legal/internet-services/itunes/us/terms.html"
        >
          http://www.apple.com/legal/internet-services/itunes/us/terms.html
        </a>{" "}
        |{" "}
        <a
          href="https://www.apple.com/legal/privacy/en-ww/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.apple.com/legal/privacy/en-ww/
        </a>
        <br />
        Google Play Store |{" "}
        <a
          href="https://play.google.com/about/play-terms/index.html"
          target="_blank"
          rel="noreferrer"
        >
          https://play.google.com/about/play-terms/index.html
        </a>{" "}
        |{" "}
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          https://policies.google.com/privacy?hl=en
        </a>{" "}
        Google Payments |{" "}
        <a
          href="https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&ldt=buyertos&ldr=uk"
          target="_blank"
          rel="noreferrer"
        >
          https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&ldt=buyertos&ldr=uk
        </a>{" "}
        |{" "}
        <a
          href="https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&ldt=privacynotice"
          target="_blank"
          rel="noreferrer"
        >
          https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&ldt=privacynotice
        </a>{" "}
        <br />
        <br />
      </p>

      <p className="header">4. YOUR RIGHT TO USE THE SERVICE</p>
      <br />
      <p className="content">
        dayo grants you the right to do the following provided you follow all of
        the rules in these Terms:
        <br />
        <br />
        i. download, install and use the App, and any updates to it provided by
        dayo from time to time, for your personal use only on a compatible
        mobile device owned or controlled by you; and <br />
        <br />
        ii. access and browse the Site for your personal use only,
        <br />
        <br />
        (the “Licenses”).
        <br />
        <br />
        The Licenses are for your personal and domestic use only. You must not
        use the Service for commercial, business or resale purposes.
        <br />
        <br />
      </p>

      <p className="header">5. TERMINATION OF YOUR RIGHT TO USE THE SERVICE</p>
      <br />
      <p className="content">
        dayo may end the Licenses at any time and for any reason (or no reason)
        by giving you at least fourteen (14) days’ notice that the Licenses have
        ended.
        <br />
        <br />
        We may also end the Licenses immediately if you break any provision of
        these Terms by ending your dayo Account without notice to you.
        <br />
        <br />
        Where any of the Licenses are terminated, you must immediately cease
        using and accessing the Service and delete any copies of the App held by
        you.
        <br />
        <br />
      </p>

      <p className="header">6. dayo ACCOUNT</p>
      <br />
      <p className="content">
        In order to use the App and earn points, you must create an dayo
        Account. You are responsible for all uses of your dayo Account whether
        by you or a third party. You should ensure that you use your personal
        mobile number for verification of your account and a strong password (if
        applicable) for your dayo Account and that the details of your password
        are kept confidential and secure at all times.
        <br />
        <br />
        You must not select as your username a name that you do not have the
        right to use, or another person’s name with the intent to impersonate
        that person. You must not transfer your dayo Account to anyone else
        without our prior written permission.
        <br />
        <br />
        Access to your dayo Account can become restricted or even impossible if
        you lose control over the mobile number to which your dayo Account is
        registered.
        <br />
        <br />
        Your dayo Account logs details of all dayos generated by you and any
        transfers of dayos to or from your dayo Account. Please review your dayo
        Account regularly. If you identify any erroneous transaction or
        unexpected activity on your dayo Account, or you believe that the
        security of your dayo Account has been compromised, you must let us know
        as soon as possible.
        <br />
        <br />
        Different membership levels may apply to the dayo Accounts of different
        users and, depending on the membership level you hold, your entitlement
        to access functionality of the App or generate dayos may vary.
        <br />
        <br />
        When using the App on your mobile device, dayo will operate in the
        background of your mobile device, which in turn may have an impact to
        the battery life of such device.
        <br />
        <br />
        The App stops generating dayos on your mobile device if you do not open
        the App for 30 days. Generation of dayos restarts once you next open the
        App.
        <br />
        <br />
        The App allows you to send your dayos to other users of the App. Please
        ensure that you have verified the identity of the recipient of the
        relevant dayos before sending them.
        <br />
        <br />
      </p>

      <p className="header">7. EARNING points</p>
      <br />
      <p className="content">
        “points” are units of exchange that users of the App may generate
        through verified social media activity only. points may be used to get
        certain benefits that are offered via the App. points can be redeemed
        for cash from dayo, unless we expressly say otherwise with you.
        <br />
        <br />
        We reserve the right to destroy points that we have reason to believe
        have not been created by verified social media activity and to suspend
        or disable any dayo Account used in such a way.
        <br />
        <br />
        We reserve the right to destroy or redistribute dayos that we have
        reason to believe have been obtained through fraud, for instance by
        defrauding another dayo user, and to suspend or disable any dayo Account
        used in such a way.
        <br />
        <br />
        We reserve the right and retain the absolute discretion to determine and
        alter, from time to time, the eligible forms of social media activity,
        social media activity verification algorithms and amounts of verified
        social media activity that must be undertaken by users in order to
        generate points using the App (for example, we may alter the number of
        verified activity required to generate a point).
        <br />
        <br />
        Details of current eligibility criteria and the volumes of verified
        social media activity required to generate points are provided within
        the FAQ section of the App. social media activity verification conducted
        by the App is considered final and cannot be revised or reversed.
        <br />
        <br />
        We may cap the maximum amount of points that users of the App may
        generate each day using the App. The applicable cap may vary depending
        upon the membership level of the relevant user or other criteria that we
        chose to apply and we may increase or decrease such caps from time to
        time.
        <br />
        <br />
      </p>

      <p className="header">8. dayo MARKETPLACE</p>
      <br />
      <p className="content">
        We are able to offer codes (“Sale Codes”) that can be used to obtain
        benefits from third-parties (this may include, but is not limited to
        discounts that can be used with retailers, websites or other
        services)(“Third-Party Benefits”).You may purchase these Sale Codes with
        dayos on a “dayo Marketplace”. Although the Sale Codes may allow you to
        obtain Third-Party Benefits, you are contracting directly with us when
        you spend your dayos on the dayo Marketplace to purchase Sale Codes.
        <br />
        <br />
        You agree that dayo has no responsibility for any Third-Party Benefits.
        You are contracting directly with the relevant third party when you
        redeem or use your Sale Codes to acquire Third-Party Benefits and any
        redemption or use happens on third party websites or apps that are not
        controlled by us. You agree that we have no control over and do not
        guarantee the quality, safety or legality of any Third-Party Benefits,
        the truth or accuracy of their offers, or the ability of third-parties
        to provide any goods, services or other benefits offered. Save as
        otherwise expressly agreed with you, we and our affiliates do not act as
        agent, contractor, partner or any form of representative of the
        third-parties who’s Third-Party Benefits are promoted via the dayo
        Marketplace. Our operation of the dayo Marketplace should not be taken
        as an endorsement (express or implied) of any goods, services or other
        benefits offered on it, nor of any third-party. <br />
        <br />
        Any redemption or use of the Sale Codes are subject to any terms that
        are stated as part of the Sales Codes).
        <br />
        <br />
        dayo is entitled to alter the selection of Sale Codes on offer, and the
        number of dayos required to redeem any such Sale Codes offered on the
        dayo Marketplace, as well as to limit the number of possible redemptions
        of any Sale Codes by any user or group of users in its absolute
        discretion.
        <br />
        <br />
        We welcome feedback on the Sale Codes.
        <br />
        <br />
      </p>

      <p className="header">9. PRIZE DRAWS</p>
      <br />
      <p className="content">
        From time to time, dayo may offer you the chance to enter into prize
        draws sponsored by one of our third-party partners. The name of the
        third-party partner will be identified when you enter the prize draw and
        any specific terms relating to the prize draw. Each prize draw will be
        subject to our general prize draw terms.
        <br />
        <br />
      </p>

      <p className="header">10. INTELLECTUAL PROPERTY RIGHTS</p>
      <br />
      <p className="content">
        All intellectual property rights in the Service (including in the App
        and the Site) throughout the world belong to us (or our licensors) and
        the rights in the Services are licensed (not sold) to you. You have no
        intellectual property rights in, or to, the Service other than the right
        to use them in accordance with these Terms.
        <br />
        <br />
        “dayo” is the name of dayo people llc and we hold registered and
        unregistered trade mark rights in respect of this name
        <br />
        <br />
      </p>

      <p className="header">11. USER CONTENT</p>
      <br />
      <p className="content">
        You are solely responsible for any data, text, images, graphics and
        other content that you submit, post or display on or via the Service
        ("Your Content").
        <br />
        <br />
        We do not claim ownership of any User Content. However, you grant to us
        a non-exclusive, worldwide, royalty-free, transferable, sub-licensable,
        perpetual license to use, aggregate, reproduce, publish, distribute,
        perform, adapt, amend, enhance and communicate and otherwise fully
        exploit Your Content for any purpose relating to the Service and our
        business, at any time.
        <br />
        <br />
        You agree that we are not responsible for, and do not endorse, Your
        Content or any data, text, images, graphics and other content that may
        be submitted, posted or displayed on or via the Service by another user
        (“Other User Content”) and that we do not have any obligation to
        monitor, edit, or remove Your Content or any Other UserContent. However,
        we reserve the right, without obligation, to monitor, moderate, edit or
        remove any such content.
        <br />
        <br />
        In general, we will only review Your Content if it is relevant to an
        issue that we are investigating either as a result of a query from you
        or another user, as a result of an appeal to an account suspension, or
        as a result of a technical problem with the Service. You agree that we
        may access and use Your Content for this purpose.
        <br />
        <br />
        You will make sure that Your Content is accurate, complete, up-to-date,
        and in compliance with all applicable laws, rules and regulations.
        <br />
        <br />
        You agree that Your Content must not:
        <br />
        <br />
      </p>
      <p className="content">
        (i) infringe any third party's copyrights or other rights (e.g., trade
        mark, privacy rights, etc.);
        <br />
        (ii) contain sexually explicit content or pornography;
        <br />
        (iii) contain hateful, defamatory, or discriminatory content or incite
        hatred against any individual or group;
        <br />
        (iv) exploit minors;
        <br />
        (v) depict unlawful acts or extreme violence;
        <br />
        (vi) depict animal cruelty or extreme violence towards animals;
        <br />
        (vii) promote fraudulent schemes, multi-level marketing (MLM) schemes,
        get rich quick schemes, online gaming and gambling, cash gifting, work
        from home businesses, or any other dubious money-making ventures; or
        <br />
        (viii) break any law, rule or regulation.
        <br />
        <br />
      </p>

      <p className="header">12. FEEDBACK</p>
      <br />
      <p className="content">
        We welcome any feedback or suggestions for improvements relating to the
        Service and/or any related products and services offered by us from time
        to time (“Feedback”). Where you provide Feedback to us you agree that we
        and our affiliates will have the right to use such Feedback for such
        purposes as we see fit from time to time, including to create new, or
        enhance existing products or services offered by us, and for consumer
        communication or product promotion. We will not be required to
        compensate you financially for any such Feedback provided, regardless of
        how we or our affiliates use it.
        <br />
      </p>

      <p className="header">13. ACCEPTABLE USE</p>
      <br />
      <p className="content">
        You agree that you shall not:
        <br />
        <br />
        a) seek to generate points by any means other than your genuine social
        media activity meeting the eligibility criteria specified by dayo from
        time to time and, in particular, you shall not (i) simulate any social
        media activity using artificial and/or mechanical means, (ii) enlist
        third parties to generate dayos on your behalf (however, you may receive
        transfers of dayos validly generated by other users using the
        functionality available within the App);
        <br />
        <br />
        b) copy, adapt, transmit, reverse engineer, decompile, disassemble,
        modify, sell the App or Site or any content accessible on either, save
        as otherwise expressly permitted by these Terms or applicable law;
        <br />
        <br />
        c) operate more than one dayo Account;
        <br />
        <br />
        d) use the Service in any unlawful manner, for any unlawful purpose, or
        in any manner inconsistent with these Terms, or act fraudulently or
        maliciously, for example, by hacking into or inserting malicious code,
        including viruses, or harmful data, into the Service;
        <br />
        <br />
        e) reproduce, frame, display or mirror the Service other than as a
        necessary consequence of their normal operation by you;
        <br />
        <br />
        f) infringe our intellectual property rights or those of any third party
        in relation to your use of the Service;
        <br />
        <br />
        g) transmit any material that is defamatory, explicit, offensive or
        otherwise objectionable on the Service;
        <br />
        <br />
        h) use the Service in a way that could damage, disable, overburden,
        impair or compromise our systems or security or interfere with other
        users;
        <br />
        <br />
        i) use the Service in any way that breaches any law or regulation,
        including any applicable export control laws, privacy laws or any other
        purpose not reasonably intended by dayo;
        <br />
        <br />
        j) employ any technology that interferes in any way with the proper
        operation of the Service, including by blocking any advertising or
        promotions displayed thereon; or
        <br />
        <br />
        k) collect or harvest any information or data from the Services for any
        commercial purpose, save as otherwise agreed in writing by us.
        <br />
        <br />
        If we discover any breach of this Section 14 by you, we may immediately
        suspend or terminate your access to the App and remove and delete any
        offending content from the Service.
        <br />
        <br />
      </p>

      <p className="header">14. CHANGES AND UPDATES TO THE SERVICE</p>
      <br />
      <p className="content">
        dayo reserves the right at any time in its absolute discretion to alter
        the functionality or contents of the Service with or without notice to
        you.
        <br />
        <br />
        We may issue updates to the App from time to time. Depending upon the
        nature of the update, you may not be able to continue to use the App
        until you have downloaded the latest version of the App.
        <br />
        <br />
      </p>

      <p className="header">15. CONTACTING US</p>
      <br />
      <p className="content">
        If you would like to contact us, including with any feedback, questions,
        complaints or claims about the Service, please contact us via our iPhone
        or Android application.
        <br />
        <br />
      </p>

      <p className="header">16. AVAILABILITY OF THE SERVICE</p>
      <br />
      <p className="content">
        The Service is provided “as is” and “as available” with all faults. We
        do not guarantee that the Service, or any part of it, will always be
        available or be uninterrupted. We may suspend, withdraw, discontinue or
        change all or any part of the Service without notice. You agree that
        your use of the Service is at your own risk. We will not be liable to
        you if the Service (or any part of it) is unavailable at any time or for
        any period.
        <br />
        <br />
      </p>

      <p className="header">17. THIRD PARTY SERVICES</p>
      <br />
      <p className="content">
        dayo may include in the dayo Services links to other sites or
        applications that are owned or operated by third parties (“Third Party
        Services”). You also agree that dayo has no control over the content of
        Third Party Services and does not have any responsibility for any
        material available on such Third Party Services nor for any use of or
        reliance on the contents of such Third Party Services by you. The fact
        that we may link to a Third Party Service does not mean that we endorse
        it or the products or services on it. In particular, where the Third
        Party Service relates to your health, you should always take appropriate
        medical advice before using it.
        <br />
        <br />
      </p>

      <p className="header">
        18. LIMITATIONS OF THE SERVICE; NO MEDICAL ADVICE
      </p>
      <br />
      <p className="content">
        The Service is provided for general information and entertainment
        purposes only. It does not offer advice on which you should rely. You
        must obtain professional or specialist advice before taking, or
        refraining from, any action on the basis of information obtained from
        the App. Although we make reasonable efforts to update the information
        provided by the App, we make no representations, warranties or
        guarantees, whether expressed or implied, that such information is
        accurate, complete or up to date.
        <br />
        <br />
        The Service does not contain or constitute, and should not be
        interpreted as, any form of medical advice or opinion, diagnosis or
        treatment, and should never be used as a substitute for medical or
        emergency care. Your use of the Service does not create a doctor-patient
        relationship between you and dayo.
        <br />
        <br />
        If you have a medical or mental health emergency, or are taking actions
        that may cause harm to you or to others, you should seek emergency
        treatment at the nearest emergency room or call an emergency helpline.
        <br />
        <br />
        We are not licensed medical professionals, and we are not in the
        business of providing medical advice or in the practice of medicine. You
        should always consult a qualified medical professional prior to
        beginning or modifying any diet or exercise program, and only your
        medical professional can determine the right course of treatment for you
        and determine what is safe, appropriate and effective based on your
        needs.
        <br />
        <br />
      </p>

      <p className="header">19. CHANGES TO THESE TERMS OF USE</p>
      <br />
      <p className="content">
        We may change these Terms from time to time. Any changes we may make to
        these Terms in the future will be posted on the Site and App and, where
        appropriate, notified to you by email. Please review this page
        frequently to see any updates or changes to these Terms.
        <br />
        <br />
        From time to time we may make available additional terms or guidance in
        relation to the usage of the Service and, where we do this, you agree to
        comply with such additional terms or guidance that we make available by
        continuing to use the Service.
        <br />
        <br />
      </p>

      <p className="header">20. OUR LIABILITY TO YOU</p>
      <br />
      <p className="content">
        The Service has not been developed to meet your individual requirements,
        and therefore it is your responsibility to ensure that the facilities
        and functions of the Service meet your requirements.
        <br />
        <br />
        We are only responsible for loss or damage you suffer that is a
        foreseeable result of our breach of these Terms or our negligence up to
        the maximum aggregate limit specified in the following paragraph.
        <br />
        <br />
        Our maximum aggregate liability to you in respect of the Service
        (whether in contract, tort (including negligence), breach of statutory
        duty or otherwise) shall in no circumstances exceed the sum of twenty
        pounds (GBP 20).
        <br />
        <br />
        We do not exclude or limit in any way our liability to you where it
        would be unlawful to do so. This includes liability for death or
        personal injury caused by our negligence or for fraud or fraudulent
        misrepresentation.
        <br />
        <br />
        To the maximum extent permitted by applicable law, we shall not be
        liable to you for any unauthorized use of your dayo Account resulting
        from your failure to maintain restricted access to your mobile device or
        your mobile SIM card, the confidentiality or security of your user
        details or failure to use a strong password.
        <br />
        <br />
        Reliance on any information provided by dayo or in connection with the
        dayo Service is solely at your own risk. You are solely responsible for
        any decisions or actions you take based on the information and materials
        available through the Service.
        <br />
        <br />
        We are not responsible for events outside our control. If our provision
        of the Service or support for the Service is delayed by an event outside
        our control then we will contact you as soon as possible to let you know
        and we will take steps to minimize the effect of the delay. Provided we
        do this we will not be liable for delays caused by the event but if
        there is a risk of substantial delay you may contact us to end your
        contract with us and receive a refund for any Service you have paid for
        but not received.
        <br />
        <br />
      </p>

      <p className="header">21. APP STORE REQUIREMENTS</p>
      <br />
      <p className="content">
        You acknowledge and agree that the availability of our App is dependent
        on the third party stores from which you download the application,
        including Apple Inc.’s (“Apple”) App Store (the “Apple App Store”) and
        Google LLC’s (“Google”) App Store (“Google Play”).
        <br />
        <br />
        As a user of the Apple App Store, we are required to include certain
        legal terms in our Terms of Use, and these are set out in Attachment 1
        to these Terms. You agree to comply with, and your license to use our
        application is conditioned upon your compliance with, such Apple App
        Store terms and conditions. To the extent such other terms and
        conditions from such Apple App Store are less restrictive than, or
        otherwise conflict with, the terms and conditions of these Terms of Use
        of Use, the more restrictive or conflicting terms and conditions in
        these Terms of Use apply.
        <br />
        <br />
        Through our App, you may purchase (“In-App Purchase”) certain goods or
        features designed to enhance the performance of the Service. When you
        make an In-App Purchase, you are doing so through the Apple iTunes
        service and you are agreeing to their respective Terms and Conditions,
        available at
        <a
          href=" http://www.apple.com/legal/internet-services/itunes/us/terms.html"
          target="_blank"
        >
          http://www.apple.com/legal/internet-services/itunes/us/terms.html.
        </a>
        dayo is not a party to any In-App Purchase.
        <br />
        <br />
      </p>

      <p className="header">22. General</p>
      <br />
      <p className="content">
        We may transfer our rights and obligations under these Terms to a third
        party. We will always tell you in writing if this happens and we will
        ensure that the transfer will not affect your rights under the contract.
        <br />
        <br />
        You are not entitled to transfer your rights or your obligations under
        these Terms without our prior written consent.
        <br />
        <br />
        If we fail to insist that you perform any of your obligations under
        these Terms of Use, or if we do not enforce our rights against you, or
        if we delay in doing so, that will not mean that we have waived our
        rights against you and will not mean that you do not have to comply with
        those obligations. If we do waive a default by you, we will only do so
        in writing, and that will not mean that we will automatically waive any
        later default by you.
        <br />
        <br />
        Each of the provisions of these Terms operates separately. If any court
        or competent authority decides that any of them are unlawful or
        unenforceable, the remaining conditions will remain in full force and
        effect.
        <br />
        <br />
        These Terms and our agreement are governed by English law. You can bring
        legal proceedings in respect of the Service in the English courts. If
        you live in Scotland you can bring legal proceedings in respect of the
        products in either the Scottish or the English courts. If you live in
        Northern Ireland you can bring legal proceedings in respect of the
        products in either the Northern Irish or the English courts.
        <br />
        <br />
        ___
        <br />
        <br />
        Attachment 1<br />
        <br />
        App Store Terms
        <br />
        <br />
        1. The Terms of Use are concluded between you and dayo, and not with
        Apple. The dayo App and its contents are the responsibility of us, our
        licensors and users of the dayo App (to the extent that such users
        create, submit or distribute any content via the dayo App) and not
        Apple.
        <br />
        <br />
        2. The license to use the dayo App granted under these Terms of Use is a
        non-transferable license for you to use the dayo App on an Apple-branded
        product that you own or control in accordance with the usage rules set
        forth in the Apple App Store Terms of Service, except that the dayo App
        may be accessed, acquired, and used by other accounts associated with
        you via any family sharing or volume purchasing arrangements with Apple.
        <br />
        <br />
        3. You and we acknowledge that Apple has no obligation whatsoever to
        furnish any maintenance and support services with respect to the dayo
        App. We do not offer maintenance or support services in connection with
        the dayo App.
        <br />
        <br />
        4. Apple will have no warranty obligation whatsoever with respect to the
        dayo App, and any claims, losses, liabilities, damages, costs or
        expenses attributable to any failure of the dayo App to conform to any
        applicable warranty set out in these Terms of Use will be solely our
        responsibility.
        <br />
        <br />
        5. We, not Apple, are responsible for addressing any claims by you or
        any third party relating to the dayo App or your possession and/or use
        of the dayo App, including, but not limited to: (i) product liability
        claims; (ii) any claim that the dayo App fails to conform to any
        applicable legal or regulatory requirement; and (iii) claims arising
        under consumer protection or similar legislation.
        <br />
        <br />
        6. In the event of any third party claim that the dayo App or your
        possession or use of the dayo App infringes that third party’s
        intellectual property rights, dayo and not Apple will be responsible for
        the investigation, defense, settlement and discharge of any such
        intellectual property infringement claim.
        <br />
        <br />
        7. You represent and warrant that (i) you are not located in a country
        that is subject to a U.S. Government embargo, or that has been
        designated by the U.S. Government as a “terrorist supporting” country;
        and (ii) you are not listed on any U.S. Government list of prohibited or
        restricted parties.
        <br />
        <br />
        8. You must comply with any third party terms that are applicable to the
        use of the dayo App from time to time.
        <br />
        <br />
        9. Apple and Apple’s subsidiaries are third party beneficiaries of the
        Terms of Use, and upon your acceptance of these Terms of Use, Apple will
        have the right (and will be deemed to have accepted the right) to
        enforce these Terms of Use against you as a third party beneficiary
        thereof.
        <br />
        <br />
        10. dayo offers optional in-app purchases in the form of auto-renewing
        subscriptions. The subscription automatically renews unless it is
        canceled at least 24 hours before the end of the current period. Your
        account will be charged for renewal within 24 hours prior to the end of
        the current period. You can manage and cancel your subscriptions by
        going to your App Store account settings after purchase.
        <br />
        <br />
      </p>
    </Box>
  );
};

export default TermAndConditionLight;

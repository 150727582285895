import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  EDIT_ADS,
  GET_ORDER_DETAILS,
  GET_ORDER_LIST,
} from "../../../constants/URLS/urls";
import { PURGE } from "redux-persist";
import { defaultMessage } from "../../../constants/Messages";
import { HTTP_OK } from "../../../constants/StatusCode";
import axiosInstance from "../../../config/axios";
const initialState = {
  isLoading: false,
  filter: {
    any: "",
    status: "",
  },
  nextPage: null,
  prevPage: null,
  orderListData: [],
  count: 0,
  totalPages: 0,
  singleOrderDetails: null,
};

//Get OrderDetails

export const getOrderDetails = createAsyncThunk(
  "orderData/getOrderDetails",
  async (params) => {
    try {
      const response = await axiosInstance.get(
        `${GET_ORDER_DETAILS}${params.id}/`
      );
      if (response.data.status_code !== HTTP_OK) {
        throw new Error(response.data.message[0]);
      }
      return response.data.data;
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);

// Get Filtered Orders Data
export const getFilterOrders = createAsyncThunk(
  "orderData/getFilterOrders",
  async (_, { getState }) => {
    let url = GET_ORDER_LIST;
    const state = getState();
    const { filter } = state.orderList;
    const { any, status } = filter;
    if (any.length > 0) {
      url += `?search=${any}`;
    }
    if (status.length > 0) {
      any.length > 0
        ? (url += `&status=${status}`)
        : (url += `?status=${status}`);
    }
    try {
      const response = await axiosInstance.get(url);
      if (response.status !== HTTP_OK) {
        throw new Error(response.data.message[0]);
      }
      const resData = response.data.data[0];
      return {
        data: resData.results,
        count: resData.count,
        next: resData.links.next,
        prev: resData.links.previous,
        totalPages: resData.links.total_pages,
      };
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);

// Get All refund data
export const getOrderData = createAsyncThunk(
  "order/getOrderData",
  async (nextUrl = null) => {
    let url = nextUrl ? nextUrl : GET_ORDER_LIST;
    try {
      const response = await axiosInstance.get(url);
      if (response.status !== HTTP_OK) {
        throw new Error(response.data.message[0]);
      }
      const resData = response.data.data[0];
      return {
        data: resData.results,
        count: resData.count,
        next: resData.links.next,
        prev: resData.links.previous,
        totalPages: resData.links.total_pages,
      };
    } catch (error) {
      let message = defaultMessage;
      if (error?.response?.data?.message[0]) {
        message = error.response.data.message[0];
      } else {
        message = error.message;
      }
      throw message;
    }
  }
);

//update Ads
export const updateAds = createAsyncThunk("ads/update", async (params) => {
  try {
    const EDIT_ADS_URL = EDIT_ADS + `${params[1]}/`;

    const response = await axiosInstance.patch(EDIT_ADS_URL, params[0]);
    if (response.data.status_code !== HTTP_OK) {
      throw new Error(response.data.message[0]);
    }
    return { params };
  } catch (error) {
    let message = defaultMessage;
    if (error?.response?.data?.message[0]) {
      message = error.response.data.message[0];
    } else {
      message = error.message;
    }
    throw message;
  }
});
const orderSlice = createSlice({
  name: "orderSlice",
  initialState,
  reducers: {
    updateFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    resetOrderList: (state, action) => {
      state.orderListData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
    builder.addCase(getOrderData.pending, (state, action) => {
      state.isLoading = action.meta.arg === undefined ? true : false;
    });
    builder.addCase(getOrderData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orderListData.push(...action.payload.data);
      state.count = action.payload.count;
      state.nextPage = action.payload.next;
      state.prevPage = action.payload.prev;
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(getOrderData.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getFilterOrders.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getFilterOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orderListData = action.payload.data;
      state.count = action.payload.count;
      state.nextPage = action.payload.next;
      state.prevPage = action.payload.prev;
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(getFilterOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.orderListData = [];
    });
    builder.addCase(getOrderDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getOrderDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleOrderDetails = action.payload;
    });
    builder.addCase(getOrderDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.singleOrderDetails = null;
    });
  },
});
export const { resetOrderList, updateFilter } = orderSlice.actions;
export default orderSlice.reducer;

export const defaultMessage = "Something went wrong please try again later";
export const emailNotFound = "Email not found";
export const invalidTimitLimit = "Please provide valid time limit";
export const dataNotFound = "Data not found!";
// Ads
export const adCreatedSuccessfully = "Ad added successfully";
export const adUpdatedSuccessfully = "Ad updated successfully";
export const adDeletedSuccessfully = "Ad deleted successfully";
export const adShouldSmall = "Ad Should not be greater then 60s";
export const videoPortrait =
  "It seems you have uploaded video in landscape mode. Please upload in portrait mode only.";
export const videoSizeError =
  "Oops! The size limit for ads is 8 MB. Reduce the file size and try again.";
export const DATA_NOT_FOUND_TO_SHARE = "Data not found for sharing!";
export const MAX_FIVE_SHARE = "There is a five-person limit for sharing data";
export const SHARE_SUCCESS = "Data shared successfully";
export const DOWNLOAD_SUCCESS = "Please check downloaded file";
export const severity = {
  success: "success",
  error: "error",
};

//Graph
export const salesAndComissionHeader = "Total Marketplace Sale: $";
export const salesAndComissionSubHeader = "Commission Earned: $";
export const salesAndComissionkey = "total_marketplace_sale";

export const buyPointsHeader = "Total Points Bought: ";
export const buyPointsSubHeader = "Total Value: $";
export const buyPointsKey = "total_points_bought";

export const subscriptionHeader = "Number of Subscriptions: ";
export const subscriptionSubHeader = "Amount: $";
export const subscriptionKey = "no_of_subscription";

export const adsPointSubHeader = "Total Points Earned: ";

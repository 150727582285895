import { Box, Typography } from "@mui/material";
import React from "react";

const RedirectionPage = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        paddingInline: "20px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
        }}
        variant="adh2"
        color="#ffffff"
      >
        For this feature to work, Dayo has to be uploaded on playstore. For
        now this is the where the dummy redirection lands.
      </Typography>
    </Box>
  );
};

export default RedirectionPage;

import { Skeleton, Stack } from "@mui/material";
import React from "react";

const ProductListSkeleton = () => {
  const staticArray = [1, 2, 3, 4, 5];
  return (
    <Stack direction="column" spacing={2}>
      {staticArray.map((item) => {
        return (
          <Stack direction="row" spacing={2} key={item}>
            <Skeleton variant="rounded" height="376px" width="278px" />
            <Skeleton variant="rounded" height="376px" width="100%" />
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ProductListSkeleton;

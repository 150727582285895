import { ButtonBase } from "@mui/material";
import React from "react";
import { fontFamily } from "../../constants/FontFamily";

const LinearButton = ({
  lineHeight = "16.8px",
  height = "100%",
  width = "100%",
  ...rest
}) => {
  return (
    <ButtonBase
      style={{
        fontWeight: 500,
        fontSize: "14px",
        ...fontFamily.medium,
        lineHeight,
        cursor: "pointer",
      }}
      sx={{
        ...rest?.sx,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: "0.8rem",
        border: "double 2px transparent",
        borderRadius: "6px",
        backgroundImage:
          "linear-gradient(#1E0338,#1E0338), linear-gradient(to right, #6100FF, #D300BE)",
        backgroundOrigin: "border-box",
        backgroundClip: "padding-box, border-box",
        height,
        width,
      }}
      onClick={rest.onClick}
    >
      {rest?.icon}
      {rest.title}
    </ButtonBase>
  );
};

export default LinearButton;

import React from "react";
import { ListItem, Box, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { ADTRACKING, CREATEAD } from "../../constants/routes";
import { fontFamily } from "../../constants/FontFamily";
function SidebarNavItem({ item, onClose }) {
  const pathName = useLocation().pathname;
  let activeFlag = false;
  if (pathName === item.href) {
    activeFlag = true;
  }
  if (
    (pathName.startsWith(CREATEAD) || pathName.startsWith("/viewAd")) &&
    item.href === ADTRACKING
  ) {
    activeFlag = true;
  }
  return (
    <ListItem
      sx={{
        width: "100%",
        padding: "23px 36px",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <NavLink
        style={{ textDecoration: "none" }}
        to={item.href}
        onClick={onClose}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            component="img"
            src={!activeFlag ? item.icon : item.activeIcon}
            alt={item.title}
            pr={2.5}
          />
          <Typography
            variant="adh5"
            color={activeFlag ? "#FFFFFF" : "text.grey"}
            sx={{
              textDecoration: "none",
              fontWeight: 500,
              lineHeight: "24px",
              ...fontFamily.medium,
            }}
          >
            {item.title}
          </Typography>
        </Box>
      </NavLink>
    </ListItem>
  );
}

export default SidebarNavItem;

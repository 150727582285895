const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

//HELP DESK REDIRECTION
export const HELP_DESK_REDIRECTION = "https://app.intercom.com/";

//USER API's
export const LOGIN_API = `${BASE_URL}user/login`;
export const EMAIL_VERIFICATION_API = `${BASE_URL}user/emailVerification`;
export const VERIFY_EMAIL_API = `${BASE_URL}user/verifyEmail`;
export const RESET_PASSWORD_API = `${BASE_URL}user/resetPasswordRequest`;
export const GENERATE_TOKEN = `${BASE_URL}user/generateToken`;
export const CHANGE_PASSWORD = `${BASE_URL}user/changePassword`;
export const LOGOUT_API = `${BASE_URL}user/logout`;

//POINTS API

export const GET_TIME_LIMIT = `${BASE_URL}points/getTimeLimit`;
export const ADD_TIME_LIMIT = `${BASE_URL}points/addTimeLimit`;
export const UPDATE_TIME_LIMIT = `${BASE_URL}points/updateTimeLimit/`;

//BRAND API
export const GET_BRAND_LIST = `${BASE_URL}ads/brandList`;

// ADS API
export const CREATE_AD = `${BASE_URL}ads/createAd`;
export const GET_ADS = `${BASE_URL}ads/adsList`;
export const EDIT_ADS = `${BASE_URL}ads/updateAds/`;
export const GET_SINGLE_AD = `${BASE_URL}ads/getAdDetail/`;
export const DELETE_AD = `${BASE_URL}ads/deleteAd/`;
export const SHARE_DOWNLOAD_AD = `${BASE_URL}ads/attachmentExport`;
export const POINT_GRAPH = `${BASE_URL}ads/getPointsGraphData`;
export const GET_DASHBOARD_DATA = `${BASE_URL}/ads/dashboardDetails`;
export const GET_REVENUE_DATA = `${BASE_URL}/ads/revenueGraph`;
export const GET_EXPENDITURE_DATA = `${BASE_URL}/ads/expenditureGraph`;
//AWS
export const GET_PRE_SIGN_URL = `${BASE_URL}ads/preSignedUrl?file_name=`;

// ORDER API
export const GET_ORDER_LIST = `${BASE_URL}payments/adminOrderFilter`;
export const GET_ORDER_DETAILS = `${BASE_URL}payments/getOrderDetail/`;

//REFUND API

export const GET_REFUND_LIST = `${BASE_URL}payments/adminReturnOrderList`;
export const UPDATE_ORDER_STATUS = `${BASE_URL}payments/adminOrderStatusUpdate/`;
export const RETURN_ORDER = `${BASE_URL}payments/adminRefundOrder`;

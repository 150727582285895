import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import authReducer from "./slices/Auth/authSlice";
import brandListReducer from "./slices/BrandList/brandListSlice";
import snakbarReducer from "./slices/Snackbar/snackbarSlice";
import timeLimitReducer from "./slices/TImeLimit/timeLimitSlice";
import adReducer from "./slices/Ad/adSlice";
import dashboardReducer from "./slices/Dashboard/dashboardSlice";
import orderRefundSlice from "./slices/Refund/refundSlice";
import ordersSlice from "./slices/Orders/ordersSlice";
const rootReducer = combineReducers({
  auth: authReducer,
  snackbar: snakbarReducer,
  timeLimit: timeLimitReducer,
  brand: brandListReducer,
  ad: adReducer,
  dashboard: dashboardReducer,
  orderRefund: orderRefundSlice,
  orderList: ordersSlice,
});
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth"],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_LOCAL_STORAGE_SECRET_KEY,
    }),
  ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

let persistor = persistStore(store);

export { store, persistor };
